<template>
  <div class="form-group">
    <div class="label required fs-xl">Distribution Channel</div>
    <div class="row">
      <div v-for="(item, i) in distributionChannels" :key="i" :class="item.class">
        <input
          :id="'type_' + item.code"
          v-model="channel"
          :checked="channel === item.code"
          :value="item.code"
          name="distribution_channel"
          type="radio"
        />
        <label :for="'type_' + item.code" class="radio-group">
          <span class="radio-title">
            <span v-if="item.icon" :class="item.icon" />
            {{ item.name }}
          </span>
          <span class="radio-description">{{ item.description }}</span>
          <span v-if="item.beta" class="company-name-info">
            <img src="../../../../assets/img/info-circle-outlined.svg" alt="" class="mr-1" /> beta
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, type ComputedRef, type PropType, watch } from 'vue'
import { useDistributionChannels } from '@/composables/AssignmentForm/useDistributionChannels'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'DistributionChannel',
  props: {
    distributionChannel: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: ['update:distributionChannel'],
  setup(props, { emit }) {
    const store = useStore()

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])

    const distributionChannels = useDistributionChannels({ isStaff })

    const channel = ref(props.distributionChannel)

    watch(channel, (value) => emit('update:distributionChannel', value))

    watch(
      () => props.distributionChannel,
      (value) => {
        channel.value = value
      },
    )

    return {
      distributionChannels,
      channel,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
