import {
  CANCELLED,
  COMPLETE,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPEN,
  OPTIMISING,
  PENDING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'
import type { StatusType } from '@/types/Models/Assignment'

type StatusColorMap = {
  [key in StatusType]: string
}

export const StatusColor: StatusColorMap = {
  [OPEN]: '#C8C8C8',
  [PENDING]: '#C8C8C8',
  [COMPLETE]: '#08A762',
  [CANCELLED]: '#FF5252',
  [VALIDATING]: '#C8C8C8',
  [VALIDATION_COMPLETE]: '#151515',
  [OPTIMISING]: '#C8C8C8',
  [REPORT_RELEASED]: '#C8C8C8',
  [REPORT_ACCEPTED]: '#058880',
  [REPORT_DENIED]: '#FF5252',
  [LABELS_CREATING]: '#C8C8C8',
  [LABELS_RELEASED]: '#08A762',
}
