<template>
  <div class="assignment-number mr-4">
    <router-link :to="assignmentsPage" class="mr-4">
      <a class="back-to position-absolute">
        <mdicon name="ChevronLeft" />
      </a>
    </router-link>
    <span class="ml-1">Assignment #{{ assignment.reference }}</span>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent, computed, type ComputedRef } from 'vue'
import { CUSTOMER_ASSIGNMENTS_LIST, STAFF_ASSIGNMENTS_LIST } from '@/types/router'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'Reference',
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])

    return {
      assignment,
      assignmentsPage: { name: isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST },
    }
  },
})
</script>
