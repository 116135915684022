<template>
  <div>
    <div>
      <RouterLink :to="detailsRoute" class="pointer text-light-green">
        {{ assignment.reference }}
      </RouterLink>
    </div>

    <div v-if="assignment.archived">
      <b-badge variant="danger"> Archived </b-badge>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import type { Assignment } from '@/types/Models/Assignment'
import { useDetailsRoute } from '@/composables/Assignment/useDetailsRoute'

export default defineComponent({
  name: 'ReferenceCell',
  props: {
    assignment: {
      type: Object as PropType<Assignment>,
      required: true,
    },
  },
  setup(props) {
    const { getDetailsRoute } = useDetailsRoute()

    const detailsRoute = computed(() => getDetailsRoute(props.assignment))

    return {
      detailsRoute,
    }
  },
})
</script>
