<template>
  <div v-if="isStaff" class="form-group mb-5">
    <label class="required fs-xl mt-0"> Customer </label>
    <div class="input-group">
      <CustomSelect
        placeholder="Choose client"
        :selected="customerId"
        :options="customers"
        :selectable="(option: UserWithLabel) => !option.status.includes('inactive')"
        :filter="customersFilter"
        @selected="onSelect"
      >
        <template #custom_option="{ option }: { option: UserWithLabel }">
          <div :class="'item-' + option.status">
            <span class="status" />
            <span class="name">{{ option.company || option.name }}</span>
            (Client number: {{ option.clientNumber }}; Email: {{ option.email }}; Phone: {{ option.phoneNumber }})
          </div>
        </template>
        <template #custom_list-footer>
          <div class="list-footer">
            <div class="create-client" @click="onCreate">Create New Customer</div>
          </div>
        </template>
      </CustomSelect>
    </div>
    <div v-if="error" class="text-danger">
      {{ error }}
    </div>

    <CreateNewCustomerModal :id="CREATE_NEW_CUSTOMER" />
  </div>
</template>

<script lang="ts">
import type { UserWithLabel } from '@/types/Models/User'
import { computed, defineComponent, type ComputedRef, type PropType, onMounted } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'
import { CREATE_NEW_CUSTOMER } from '@/const/ModalTypes'
import CreateNewCustomerModal from '@/views/Modals/Customer/CreateNewCustomerModal.vue'

export default defineComponent({
  name: 'Customer',
  components: {
    CreateNewCustomerModal,
    CustomSelect,
  },
  props: {
    customerId: {
      type: String as PropType<string>,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['update:customerId'],
  setup(_, { emit }) {
    const store = useStore()

    const userId: ComputedRef<string> = computed(() => store.getters['account/userId'])
    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const customers: ComputedRef<UserWithLabel[]> = computed(() => store.getters['customers/all'])

    const customersFilter = (option: any, label: any, search: string) => {
      const temp = search.toLowerCase()
      if (option.company !== null) {
        return option.label.toLowerCase().indexOf(temp) > -1 || option?.company.toLowerCase().indexOf(temp) > -1
      }
      return label
    }

    const onSelect = (customerId: string) => emit('update:customerId', customerId)

    const onCreate = () => openModal(CREATE_NEW_CUSTOMER)

    onMounted(() => {
      /**
       * For staff users we need to load and display list of customers for administrator to pick from
       * For customers we just need to pre-set current user id as customerId
       */
      if (isStaff.value) {
        store.dispatch('customers/fetchAll')
      } else {
        onSelect(userId.value)
      }
    })

    return {
      isStaff,
      customers,
      customersFilter,
      onSelect,
      onCreate,
      CREATE_NEW_CUSTOMER,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
