<template>
  <div class="text-center pt-4">
    <h1>
      <span v-if="title"> {{ title }} </span>
      <span v-else> Not found </span>
    </h1>
    <h3 v-if="subtitle" class="pt-3">
      {{ subtitle }}
    </h3>
    <div class="not-found-exit-link pt-3">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotFoundPage',
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
  },
})
</script>

<style lang="scss" scoped>
.not-found-exit-link {
  font-size: 16px;
  line-height: 34px;
  color: $gray-color;
  a {
    text-decoration: underline;
    color: $green;
  }
}
</style>
