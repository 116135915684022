<template>
  <div>
    <AssignmentStripe />
    <ItemsArea v-if="itemsAreCreated" />
    <BatchCreatingItems v-else />
  </div>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineAsyncComponent, defineComponent } from 'vue'
import type { Assignment } from '@/types/Models/Assignment'
import { useStore } from 'vuex'
import { VALIDATING, PENDING } from '@/const/AssignmentStatuses'
import BatchCreatingItems from '@/views/Assignments/Details/Batch/CreatingItems.vue'

export default defineComponent({
  name: 'BatchAssignmentDetails',
  components: {
    BatchCreatingItems,
    AssignmentStripe: defineAsyncComponent(() => import('@/views/Components/Assignment/Stripe/AssignmentStripe.vue')),
    ItemsArea: defineAsyncComponent(() => import('@/views/Assignments/Details/Batch/ItemsArea.vue')),
  },
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    const itemsAreCreated = computed(() => ![PENDING, VALIDATING].includes(assignment.value.status))

    return {
      itemsAreCreated,
    }
  },
})
</script>
