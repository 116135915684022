import type { DimensionsRules, RulesBucket } from '@/composables/Pieces/Validation/Dimensions'

export class LetterDimensions implements DimensionsRules {
  weight(): RulesBucket {
    return { required: true, numeric: true, min_value: 1, max_value: 2000 }
  }

  width(): RulesBucket {
    return { required: true, numeric: true, min_value: 1, max_value: 30 }
  }

  length(): RulesBucket {
    return { required: true, numeric: true, min_value: 1, max_value: 30 }
  }

  height(): RulesBucket {
    return { required: true, numeric: true, min_value: 1, max_value: 3 }
  }
}
