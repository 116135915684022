<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#FF5252', color: '#FFFFFF' }"
    class="ml-3"
    @click="performAction"
  >
    <i class="fa fa-close" />
    Reject report
  </b-button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'

export default defineComponent({
  name: 'RejectReport',
  setup() {
    const store = useStore()
    const isStaff = computed(() => store.getters['account/isStaff'])

    const performAction = () => {
      openModal('confirmWrap', {
        title: 'Are you sure you want to reject report?',
        message: isStaff.value
          ? 'Report will be rejected on behalf of the client'
          : 'This is an irreversible action. You will not be able to proceed after the report is rejected.',
        okButtonText: 'Reject',
        onConfirm: () => {
          store.dispatch('assignment/assignmentSummary/submitReportDeny').then(() => {
            notify({ group: 'success', text: 'Report has been rejected' })
          })
        },
      })
    }

    return {
      performAction,
    }
  },
})
</script>
