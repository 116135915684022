<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      {{ currentItemsIndexes.from }} - {{ currentItemsIndexes.to }} of {{ records }}
      {{ records > 1 ? 'items' : 'item' }}
    </div>
    <div v-if="pages > 1" class="d-flex align-items-center">
      <div>{{ currentPage }}</div>
      <div class="ml-2 d-flex align-items-center">
        <b-dropdown no-caret lazy variant="light" class="pagination-block">
          <template #button-content>
            <div class="pagination-arrow" />
          </template>
          <template v-for="val in pages" :key="`pagination_key_${val}`">
            <b-dropdown-item @click="handlePageSelected(val)">
              {{ val }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
      <div class="ml-2">of {{ pages }} {{ pages > 1 ? 'pages' : 'page' }}</div>
      <div class="ml-3">
        <div class="wrap-page-btn">
          <span class="btn-table-action" @click="prevPage">
            <b-icon
              icon="caret-left-fill"
              scale="1"
              shift-h="-25"
              :class="{ pointer: !firstPageSelected }"
              :variant="firstPageSelected ? 'secondary' : 'dark'"
            />
          </span>

          <span class="btn-table-action" @click="nextPage">
            <b-icon
              icon="caret-right-fill"
              scale="1"
              shift-h="25"
              :class="{ pointer: !lastPageSelected }"
              :variant="lastPageSelected ? 'secondary' : 'dark'"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'

export default defineComponent({
  name: 'PackingSlipPagination',
  components: { BIcon },
  props: {
    value: {
      type: Number,
      default: 1,
    },
    records: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const currentPage = ref(props.value)

    const currentItemsIndexes = computed(() => {
      const firstItemIndex = (currentPage.value - 1) * props.perPage + 1
      const lastItemIndex = currentPage.value * props.perPage
      return {
        from: firstItemIndex || 1,
        to: lastItemIndex > props.records ? props.records : lastItemIndex,
      }
    })

    const pages = computed(() => (props.records ? Math.ceil(props.records / props.perPage) : 1))

    const firstPageSelected = computed(() => currentPage.value === 1)
    const lastPageSelected = computed(() => currentPage.value === pages.value || pages.value === 0)

    watch(
      () => props.value,
      (newVal) => {
        currentPage.value = newVal || 1
      },
    )

    const handlePageSelected = (page: number) => {
      if (currentPage.value === page) return
      emit('input', page)
    }

    const prevPage = () => {
      if (currentPage.value <= 1) return
      handlePageSelected(currentPage.value - 1)
    }

    const nextPage = () => {
      if (currentPage.value >= pages.value) return
      handlePageSelected(currentPage.value + 1)
    }

    return {
      currentPage,
      currentItemsIndexes,
      pages,
      firstPageSelected,
      lastPageSelected,
      handlePageSelected,
      prevPage,
      nextPage,
    }
  },
})
</script>
