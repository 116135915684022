import type { DimensionsRules, RulesBucket } from '@/composables/Pieces/Validation/Dimensions'

export class ParcelBusinessDimensions implements DimensionsRules {
  weight(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 100,
      max_value: 35000,
    }
  }

  width(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 11,
      max_value: 50,
    }
  }

  length(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 15,
      max_value: 150,
    }
  }

  height(): RulesBucket {
    return {
      required: true,
      numeric: true,
      min_value: 3.5,
      max_value: 50,
    }
  }
}
