export const OPEN = 'open'
export const PENDING = 'pending'
export const VALIDATING = 'validating'
export const VALIDATION_COMPLETE = 'validation_complete'
export const OPTIMISING = 'optimising'
export const REPORT_RELEASED = 'report_released'
export const REPORT_ACCEPTED = 'report_accepted'
export const REPORT_DENIED = 'report_denied'
export const LABELS_CREATING = 'labels_creating'
export const LABELS_RELEASED = 'labels_released'
export const CANCELLED = 'cancelled'
export const COMPLETE = 'complete'

export const EDITABLE_STATUSES = [VALIDATION_COMPLETE, REPORT_DENIED]

export default {
  OPEN,
  PENDING,
  VALIDATING,
  VALIDATION_COMPLETE,
  OPTIMISING,
  REPORT_RELEASED,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  LABELS_CREATING,
  LABELS_RELEASED,
  CANCELLED,
  COMPLETE,
}
