<template>
  <div>
    <div>
      <a class="primary-shipment-link" :href="getLinkToTrackingPage(shipment.shipmentNumber)" target="_blank">
        {{ shipment.shipmentNumber }}
      </a>
    </div>
    <div>
      <small>
        <AnnouncementStatus :carrier="shipment.carrierHandle" :status="shipment.announcementStatus" />
      </small>
    </div>
    <div v-if="shipment.adjustedShipmentNumber">
      <small class="text-success">
        Changed to
        <a class="secondary-shipment-link" :href="getLinkToTrackingPage(shipment.shipmentNumber)" target="_blank">
          {{ shipment.adjustedShipmentNumber }}
        </a>
      </small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { getLinkToTrackingPage } from '@/services/Tracking'
import type { Shipment } from '@/types/Models/Shipment'
import AnnouncementStatus from '@/views/Components/Carrier/AnnouncementStatus.vue'

export default defineComponent({
  name: 'ShipmentNumberCell',
  components: { AnnouncementStatus },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup() {
    return {
      getLinkToTrackingPage,
    }
  },
})
</script>

<style lang="scss" scoped>
.primary-shipment-link {
  text-decoration: underline;
  color: #058880;

  &:hover {
    text-decoration: none;
  }
}

.secondary-shipment-link {
  text-decoration: underline;
  color: #08a762;

  &:hover {
    text-decoration: none;
  }
}
</style>
