import type { ComputedRef } from 'vue'
import type { Assignment } from '@/types/Models/Assignment'
import { computed } from 'vue'
import { StatusName } from '@/services/Status/StatusName'
import { StatusColor } from '@/services/Status/StatusColor'

type Props = {
  assignment: ComputedRef<Assignment>
}

export const useAssignmentStatus = ({ assignment }: Props) => {
  const statusName = computed(() => StatusName[assignment.value.type][assignment.value.status])

  const statusShortName = computed(() => {
    const words = statusName.value.split(' ')

    return words.map((word: string) => word.charAt(0).toUpperCase()).join('')
  })

  const statusColor = computed(() => StatusColor[assignment.value.status])

  const circleStyle = computed(() => {
    return {
      color: statusColor.value,
    }
  })

  return {
    statusName,
    statusShortName,
    circleStyle,
  }
}
