<template>
  <div class="page-footer d-flex align-items-center justify-content-end w-100 bg-white px-5 py-3 mb-3">
    <b-alert
      v-if="isFinishedStatus"
      :model-value="true"
      variant="secondary"
      class="alert-info-danger d-flex align-items-center mr-3 mb-0"
    >
      <span><b>Important: </b>You cannot change prices level for this assignment</span>
    </b-alert>
    <b-alert v-else :model-value="true" variant="secondary" class="popup-info d-inline-block mr-3">
      <mdicon name="InformationOutline" class="mr-1" />
      Changes will be made in the next assignment
    </b-alert>
    <b-button
      variant="success"
      size="lg"
      class="btn-green btn-save"
      :disabled="isDisabled || isFinishedStatus"
      @click="savePrices"
    >
      Save
    </b-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { CANCELLED, COMPLETE } from '@/const/AssignmentStatuses'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'PageFooter',
  emits: ['save-prices'],
  setup(_, { emit }) {
    const store = useStore()

    const changedPrices = computed(() => store.getters['prices/changedPrices'])
    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    const isDisabled = computed(() => changedPrices.value.length === 0)

    const isFinishedStatus = computed(() => {
      if (assignment.value) {
        return assignment.value.status === COMPLETE || assignment.value.status === CANCELLED
      }
      return false
    })

    const savePrices = () => {
      emit('save-prices')
    }

    return {
      isDisabled,
      isFinishedStatus,
      savePrices,
    }
  },
})
</script>

<style lang="scss" scoped>
.page-footer {
  min-height: 108px;

  .btn-save {
    min-width: 140px;
    height: 48px;
  }
}
</style>
