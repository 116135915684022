<template>
  <div>
    <div>The following assignments have the labels created quite long ago:</div>
    <ul class="labels-warning-list pt-3">
      <li v-for="assignment of assignments" :key="assignment.id">
        <b>{{ assignment.reference }}</b>
        ({{ readableDate(assignment.labelsCreatedAt) }} {{ readableTime(assignment.labelsCreatedAt) }})
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { readableDate, readableTime } from '@/services/Helpers'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'MultipleAssignments',
  props: {
    assignments: {
      type: Object as PropType<Assignment[]>,
      required: true,
    },
  },
  setup() {
    return {
      readableDate,
      readableTime,
    }
  },
})
</script>

<style lang="scss" scoped>
.labels-warning-list {
  padding-left: 15px;

  li {
    list-style: outside disc;
    font-size: 12px;
  }
}
</style>
