<template>
  <div class="row">
    <div class="col-12">
      <button class="btn create-btn btn-green w-100" @click="openDownloadLabelsModal">Download Label</button>
    </div>
    <br />
    <div v-if="documentsUrl" class="col-12">
      <button class="btn create-btn btn-green w-100" @click="openDownloadDocuments">
        Download secondary documents
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import { computed, type ComputedRef, defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import { registerModals } from '@/services/Modal'
import { useDownloadLabelsAction } from '@/composables/Labels/useDownloadLabelsAction'
import { DOWNLOAD_LABELS, DOWNLOAD_LABELS_WARNING } from '@/const/ModalTypes'

export default defineComponent({
  name: 'LabelsReleased',
  setup() {
    const store = useStore()
    const downloadLabelsAction = useDownloadLabelsAction()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const documentsUrl: ComputedRef<string> = computed(() => assignment.value?.documentsUrl || '')

    const openDownloadLabelsModal = () => (assignment.value ? downloadLabelsAction([assignment.value]) : null)

    const openDownloadDocuments = () => {
      window?.open(documentsUrl.value, '_blank')
    }

    onMounted(() => {
      registerModals(DOWNLOAD_LABELS)
      registerModals(DOWNLOAD_LABELS_WARNING)
    })

    return {
      openDownloadLabelsModal,
      openDownloadDocuments,
      documentsUrl,
    }
  },
})
</script>
