<template>
  <router-link size="md" class="ml-3 btn no-wrap" :style="{ backgroundColor: '#FFCA10', color: '#151515' }" :to="to">
    <i class="fa fa-list" />
    View report
  </router-link>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT, STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT } from '@/types/router'

export default defineComponent({
  name: 'ViewReport',
  setup() {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const assignmentId = computed(() => store.getters['assignment/assignmentSummary/getId'])
    const routeName = computed(() =>
      isStaff.value ? STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT : CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
    )
    const to = computed(() => ({
      name: routeName.value,
      params: { id: assignmentId.value },
    }))

    return {
      to,
    }
  },
})
</script>
