<template>
  <b-button
    size="md"
    variant="link"
    :style="{ backgroundColor: '#08A762', color: '#ffffff' }"
    class="ml-3"
    @click="performAction"
  >
    <i class="fa fa-check" />
    Accept report
  </b-button>
  <confirm
    :id="CONFIRM_REPORT"
    title="Are you sure you want to accept the report?"
    :message="message"
    :on-confirm="onConfirm"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'
import { CONFIRM_REPORT } from '@/const/ModalTypes'
import Confirm from '@/views/Modals/Confirm.vue'

export default defineComponent({
  name: 'AcceptReport',
  components: { Confirm },
  setup() {
    const store = useStore()
    const isStaff = computed(() => store.getters['account/isStaff'])

    const message = computed(() => {
      return isStaff.value
        ? 'Report will be accepted on behalf of the client'
        : 'You will be able to create labels after the report is accepted'
    })

    const onConfirm = async () => {
      await store.dispatch('assignment/assignmentSummary/submitReportAccept')
      notify({ group: 'success', text: 'Report has been accepted' })
    }

    const performAction = () => {
      openModal(CONFIRM_REPORT)
    }

    return {
      message,
      onConfirm,
      performAction,
      CONFIRM_REPORT,
    }
  },
})
</script>
