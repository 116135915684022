<template>
  <div v-if="isSupported" class="pickup-block">
    <PickupCalendar :pickup-error="error" :max-period="maxPickupPeriod" @select-date="setDate" />

    <b-form-group class="mt-5 pickup-instructions">
      <label>Pickup Instructions</label>
      <div class="input-group">
        <input
          v-model="pickupInstructions"
          type="text"
          placeholder="Eg. Pickup behind the little small red house"
          class="form-control"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, type PropType, watch } from 'vue'
import { PARCEL } from '@/const/DistributionChannels'
import PickupCalendar from '@/views/Components/Assignment/PickupCalendar.vue'

export default defineComponent({
  name: 'PickupBlock',
  components: { PickupCalendar },
  props: {
    distributionChannel: {
      type: String as PropType<string>,
      required: true,
    },
    isSwedenPicked: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    instructions: {
      type: String as PropType<string>,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:date', 'update:instructions'],
  setup(props, { emit }) {
    const pickupInstructions = ref(props.instructions)

    watch(pickupInstructions, (value) => emit('update:instructions', value))

    watch(
      () => props.instructions,
      (value) => {
        pickupInstructions.value = value
      },
    )

    const setDate = (value: string) => emit('update:date', value)

    const maxPickupPeriod = computed(() => {
      // DHL Express max pickup date period is 10 days after current day
      // DHL Freight max pickup date period is 100 days after current day
      return !props.isSwedenPicked ? 10 : 100
    })

    const isSupported = computed(() => props.distributionChannel === PARCEL)

    return {
      maxPickupPeriod,
      isSupported,
      pickupInstructions,
      setDate,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
