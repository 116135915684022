import type { Address, BasicAddressData, Customer } from '@/types/Models/Customer'

export const useAddressTransformers = () => {
  const toAddressString = (address?: BasicAddressData | Address | null) => {
    if (!address) return ''
    const parts = [
      address.countryCode !== 'SE' ? address.countryCode : null,
      address.postalCode,
      address.city,
      address.region,
      address.addressLine,
      address.addressLine2,
    ]

    return parts.filter(Boolean).join(', ')
  }

  const addressOwner = (address?: Address | Customer | null) => {
    if (!address) return ''
    const personNameParts = [address.firstName, address.lastName]

    const personName = address.fullName || personNameParts.filter(Boolean).join(' ')

    if (address.company && personName) {
      return `${address.company} (${personName})`
    }

    if (address.company) {
      return address.company
    }

    return personName
  }

  return {
    toAddressString,
    addressOwner,
  }
}
