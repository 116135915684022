<template>
  <div class="user-info-block mt-4 mb-4">
    <div class="wrap-box-detail">
      <h3>
        General info
        <span class="btn-table-action" @click="openModal(EDIT_USER_GENERAL_INFO_MODAL, user)">
          <mdicon name="pencil-outline" class="pointer" size="16" />
        </span>
      </h3>
      <div class="bg-white border rounded align-self-stretch brand-border-color box-detail">
        <table class="table table-borderless w-100">
          <tbody>
            <template v-for="fieldName in generalInfoFields" :key="`${user.id}-${fieldName}`">
              <tr>
                <td class="p-2">{{ userFieldNames[fieldName] }}</td>
                <td class="p-2 text-right">{{ user[fieldName] }}</td>
              </tr>
            </template>
            <tr :key="`${user.id}-salesman`">
              <td class="p-2">Assigned Salesman</td>
              <td class="p-2 text-right">
                {{ getSalesmanInfo(user.salesman) }}
              </td>
            </tr>
            <tr>
              <td class="p-2">API Keys</td>
              <td class="p-2 text-right">
                <ApiKeysModal :user="user" />
              </td>
            </tr>
            <tr>
              <td class="p-2">Email Notifications</td>
              <td class="p-2 text-right">
                <ShipmentNotificationModal :user="user" />
              </td>
            </tr>
            <tr>
              <td class="p-2">Integrations</td>
              <td class="p-2 text-right">
                <b-button class="p-2 fz-inherit" variant="outline-secondary" @click="openIntegrationsModal(user)">
                  <div class="d-flex align-items-center">
                    <mdicon name="CogOutline" class="color-black mr-2" size="36" />
                    <span class="color-black">Settings</span>
                  </div>
                </b-button>
              </td>
            </tr>
            <tr>
              <td class="p-2">Buffering</td>
              <td class="p-2 text-right">
                <BufferingModal :user="user" />
              </td>
            </tr>
            <tr>
              <td class="p-2">Batch Tier</td>
              <td class="p-2 text-right">
                <BatchTierModal :user="user" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="wrap-box-detail mx-4">
      <h3>
        Address
        <span class="btn-table-action" @click="openModal(EDIT_USER_ADDRESS_MODAL, user)">
          <mdicon name="pencil-outline" class="pointer" size="16" />
        </span>
      </h3>
      <div class="bg-white border rounded brand-border-color box-detail">
        <div class="mb-2 invoice-address">
          <div class="p-2 d-flex">
            <span class="box-detail-subtitle">Invoice Address</span>
            <hr class="mt-2 p-1 ml-3 flex-grow-1" />
          </div>
          <table class="table table-borderless w-100">
            <tbody>
              <template v-for="fieldName in addressFields" :key="`${user.id}-invoiceAddress-${fieldName}`">
                <template v-if="fieldName !== 'fullName' && fieldName !== 'clientNumber'">
                  <tr>
                    <td class="p-2">
                      {{ userFieldNames.address[fieldName] }}
                    </td>
                    <td class="p-2 text-right">
                      {{ user.clientAddress ? user.clientAddress[fieldName] : '' || '' }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div class="shipping-address">
          <div class="p-2 d-flex">
            <span class="box-detail-subtitle">Sender Address</span>
            <hr class="mt-2 p-1 ml-3 flex-grow-1" />
          </div>
          <table class="table table-borderless w-100">
            <tbody>
              <template v-for="fieldName in addressFields" :key="`${user.id}-invoiceAddress-${fieldName}`">
                <tr>
                  <td class="p-2">
                    {{ userFieldNames.address[fieldName] }}
                  </td>
                  <td class="p-2 float-right">
                    {{ user.senderAddress ? user.senderAddress[fieldName] : '' || '' }}
                  </td>
                </tr>
              </template>
              <tr>
                <td class="p-2">
                  <b-button class="p-2 fz-inherit" variant="outline-secondary" @click="openSenderAddressesModal(user)">
                    <div class="d-flex align-items-center">
                      <mdicon name="CogOutline" class="color-black mr-2" size="36" />
                      <span class="color-black">Addresses Settings</span>
                    </div>
                  </b-button>
                </td>
                <td class="p-2 text-right">
                  <router-link :to="{ name: STAFF_SENDER_ADDRESS_PRICES, params: { id: user.id } }">
                    <b-button class="p-2 fz-inherit" variant="outline-secondary">
                      <div class="d-flex align-items-center">
                        <mdicon name="CogOutline" class="color-black mr-2" size="36" />
                        <span class="color-black">Addresses Prices</span>
                      </div>
                    </b-button>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="wrap-box-detail">
      <div class="box-inner-column">
        <h3>
          Note about client
          <span v-if="!isEditMode" class="btn-table-action" @click="isEditMode = true">
            <mdicon name="pencil-outline" class="pointer" size="16" />
          </span>
          <span v-else class="d-inline-block align-middle lh-1">
            <span @click="cancelNote">
              <b-icon icon="x" :scale="1.5" variant="danger" class="pointer btn-table-action" />
            </span>
            <span @click="saveNote">
              <b-icon icon="check" class="btn-table-action pointer" :scale="1.5" variant="success" />
            </span>
          </span>
        </h3>
        <div v-if="!isEditMode" class="bg-white border rounded brand-border-color box-detail">
          <pre class="user-note">
            {{ note }}
          </pre>
        </div>
        <textarea v-else v-model="note" class="form-control bg-white border rounded brand-border-color box-detail" />
      </div>

      <div class="box-inner-column mt-4">
        <h3>
          Dynamic Pricing
          <router-link :to="{ name: STAFF_CUSTOMERS_PRICES, params: { id: user.id } }">
            <span class="btn-table-action">
              <mdicon name="pencil-outline" class="pointer" size="16" />
            </span>
          </router-link>
        </h3>
        <div class="bg-white border rounded brand-border-color box-detail">
          <DynamicPricingStatus
            :ref="'customer' + user.id"
            :user-id="user.id"
            :has-custom-prices="user.hasCustomPrices"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { User } from '@/types/Models/User'
import type { KeyAccessor } from '@/types/Helpers/Arrays'
import { none } from '@/services/Helpers'
import { useStore } from 'vuex'
import { defineComponent, type PropType, ref } from 'vue'
import { openModal } from '@/services/Modal'
import { STAFF_CUSTOMERS_PRICES, STAFF_SENDER_ADDRESS_PRICES } from '@/types/router'
import {
  EDIT_USER_ADDRESS_MODAL,
  EDIT_USER_GENERAL_INFO_MODAL,
  INTEGRATIONS,
  SENDER_ADDRESSES,
} from '@/const/ModalTypes'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'
import ApiKeysModal from '@/views/Modals/Customer/ApiKeysModal.vue'
import BufferingModal from '@/views/Modals/Customer/BufferingModal.vue'
import DynamicPricingStatus from '@/views/Components/Customer/DynamicPricingStatus.vue'
import ShipmentNotificationModal from '@/views/Modals/Customer/ShipmentNotificationModal.vue'
import BatchTierModal from '@/views/Modals/Customer/BatchTierModal.vue'

export default defineComponent({
  name: 'ChildRowUserData',
  components: {
    BatchTierModal,
    BufferingModal,
    BIcon,
    ShipmentNotificationModal,
    DynamicPricingStatus,
    ApiKeysModal,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const note = ref(props.user.note)
    const isEditMode = ref(false)
    const addressFields = ['fullName', 'countryCode', 'city', 'addressLine', 'addressLine2', 'clientNumber']
    const generalInfoFields = [
      'email',
      'clientNumber',
      'firstName',
      'lastName',
      'company',
      'phoneNumber',
      'shortName',
      'contractParty',
    ]
    const userFieldNames: KeyAccessor = {
      email: 'Email address',
      clientNumber: 'Client №',
      firstName: 'First name',
      lastName: 'Last name',
      company: 'Company',
      address: {
        fullName: 'Name',
        countryCode: 'Client Country',
        city: 'Client City *',
        zip: '',
        addressLine: 'Client Address 1',
        addressLine2: 'Client Address 2',
        clientNumber: 'Client №',
      },
      phoneNumber: 'Phone number',
      shortName: 'Short name',
      contractParty: 'Contract party',
      salesman: 'Assigned Salesman',
    }

    const getSalesmanInfo = (salesman: any) => {
      if (salesman && Object.keys(salesman).length > 0) {
        const fullName = `${salesman.first_name} ${salesman.last_name}`
        return fullName.length > 2 ? fullName : salesman.email
      }
      return ''
    }

    const openSenderAddressesModal = (data: any = {}) => {
      store
        .dispatch('customers/senderAddresses/fetch', data.id)
        .then(() => {
          openModal(SENDER_ADDRESSES, data)
        })
        .catch(none)
    }

    const openIntegrationsModal = (data: any = {}) => {
      store
        .dispatch('customers/integrations/fetch', data.id)
        .then((res) => {
          openModal(INTEGRATIONS, {
            integrations: res.data || [],
            ...data,
          })
        })
        .catch(none)
    }
    const saveNote = () => {
      store
        .dispatch('customers/update', {
          id: props.user.id,
          data: { note: note.value },
        })
        .then(() => {
          isEditMode.value = false
        })
    }
    const cancelNote = () => {
      note.value = props.user.note
      isEditMode.value = false
    }

    return {
      note,
      saveNote,
      cancelNote,
      isEditMode,
      addressFields,
      userFieldNames,
      generalInfoFields,
      openModal,
      getSalesmanInfo,
      openIntegrationsModal,
      openSenderAddressesModal,

      STAFF_CUSTOMERS_PRICES,
      STAFF_SENDER_ADDRESS_PRICES,
      EDIT_USER_GENERAL_INFO_MODAL,
      EDIT_USER_ADDRESS_MODAL,
    }
  },
})
</script>

<style scoped>
.user-note {
  white-space: pre-line;
  font-family: inherit;
  font-size: 16px;
}

.user-info-block {
  .table td,
  .table th {
    height: auto !important;
    border-top: none;
    border-bottom: none;
  }
}
</style>
