<template>
  <div>
    <h3 class="mt-4">After optimization</h3>
    <OptimizationTable header-color="dark" class="table-report bg-dark-green-title">
      <tr v-for="(report, index) in reportData.optimization" :key="'after_report_' + index">
        <td>{{ ucFirst(report.carrier) }}</td>
        <td>{{ ucFirst(report.productName) }}</td>
        <td>{{ contract }}</td>
        <td>{{ report.itemsCount }}</td>
        <td>{{ itemsCount }} pc</td>
        <td>{{ report.averageWeight }} g</td>
        <td>{{ report.averagePrice }} kr</td>
        <td>{{ report.totalPrice }} kr</td>
      </tr>

      <tr class="footer-cells">
        <td colspan="3" class="fw-400">Total</td>
        <td class="fw-400">
          {{ productManagement }}
        </td>
        <td colspan="3" class="fw-400">Average Saving per Package</td>
        <td class="fw-400">{{ reportData.averageSavingsPerPackage }} kr</td>
      </tr>

      <tr class="footer-cells total-savings">
        <td colspan="4" class="border-0" />
        <td colspan="3" class="align-middle fw-400">Total Savings</td>
        <td>
          <div class="d-flex align-items-center fw-400 ws-pre-line">
            {{ reportData.totalSavings }} kr
            <span v-if="reportData.percentSaved > 0" class="ml-2 bg-green fw-400 total-saved">
              {{ reportData.percentSaved }}% Saved
            </span>
          </div>
        </td>
      </tr>
      <tr class="footer-cells total-sum">
        <td colspan="4" class="border-0" />
        <td colspan="3" class="align-middle fw-400">Total Cost</td>
        <td class="color-black fw-400">{{ reportData.optimizationCost }} kr</td>
      </tr>
    </OptimizationTable>
  </div>
</template>

<script lang="ts">
import type { Report } from '@/types/Models/Report'
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { ucFirst } from '@/services/Helpers'
import OptimizationTable from '@/views/Components/Elements/Table/OptimizationTable.vue'

export default defineComponent({
  name: 'AfterOptimizationTable',
  components: { OptimizationTable },
  setup() {
    const store = useStore()

    const reportData: ComputedRef<Report> = computed(() => store.getters['assignment/optimizationReport/all'])
    const assignment: ComputedRef = computed(() => store.getters['assignment/assignmentSummary/get'])
    const contract: ComputedRef<string> = computed(() => assignment.value.contract)
    const itemsCount: ComputedRef<number> = computed(() => assignment.value.itemsCount)
    const productManagement: ComputedRef = computed(() => {
      let sum = 0
      reportData.value.optimization.forEach((el: any) => {
        sum += el.itemsCount
      })
      return sum
    })
    const totalSavings: ComputedRef<string> = computed(() => reportData.value.totalSavings.trim())

    return {
      reportData,
      contract,
      itemsCount,
      productManagement,
      totalSavings,
      ucFirst,
    }
  },
})
</script>

<style lang="scss" scoped>
.total-savings {
  td:last-child {
    font-size: 18px !important;
  }
}
.total-sum {
  td:last-child {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0;
  }
}
span.bg-green {
  padding: 1.5px 5px;
  font-size: 11px;
  line-height: 17px;
  display: inline-block;
  background: rgba(8, 167, 98, 0.15);
  border-radius: 32px;
}
.preliminary-report {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 12px;
  width: 175px;
  height: 40px;
  background: rgba(255, 82, 82, 0.1);
  border-radius: 2px;
  margin-left: 30px;
  color: #ff4e10;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  &__logo {
    margin-right: 9.5px;
  }
}
.color-black {
  color: black !important;
}

.table-report {
  :deep(thead td) {
    font-weight: 400;
  }
}
.fw-400 {
  font-weight: 400 !important;
}
.total-saved {
  display: block;
  line-height: 18px;
}
.ws-pre-line {
  white-space: pre-line;
}
.footer-cells {
  td {
    text-align: initial !important;
  }
}
</style>
