import type { ActionContext } from 'vuex'
import type { OptimizationReportStoreState, RootStoreState } from '@/types/Store'
import type { Report } from '@/types/Models/Report'
import type { AxiosItem } from '@/types/Components/Response'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import { LoadingStatus } from '@/const/LoadingStatus'

export const FETCH_REPORT_DATA = 'SET_REPORT_DATA'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const RESET_STATE = 'RESET_STATE'

type Context = ActionContext<OptimizationReportStoreState, RootStoreState>

const defaultReport = {
  manual: [],
  optimization: [],
  manualTotal: '0',
  percentSaved: 0,
  averageSavingsPerPackage: '0',
  info: {
    customer: '',
    reference: '',
    date: '',
    distributionStart: '',
    serviceLevel: '',
    fileName: null,
    workName: '',
    totalAmount: 0,
    customerNumber: '',
    invoiceReference: '',
    shortName: '',
    totalWeight: '',
    distributionChannel: '',
  },
  totalSavings: '0',
  optimizationCost: '0',
  optimizationProductionManagement: 0,
  hidePrice: false,
}

const state: OptimizationReportStoreState = {
  status: LoadingStatus.PENDING,
  report: JSON.parse(JSON.stringify(defaultReport)),
}

const getters = {
  all(state: OptimizationReportStoreState) {
    return state.report
  },
  loadingStatus(state: OptimizationReportStoreState) {
    return state.status
  },
}

const mutations = {
  [FETCH_REPORT_DATA](state: OptimizationReportStoreState, data: Report) {
    state.report.manual = data.manual
    state.report.optimization = data.optimization
    state.report.manualTotal = data.manualTotal
    state.report.totalSavings = data.totalSavings
    state.report.percentSaved = data.percentSaved
    state.report.averageSavingsPerPackage = data.averageSavingsPerPackage
    state.report.info = data.info
    state.report.optimizationCost = data.optimizationCost
  },
  [SET_LOADING_STATUS](state: OptimizationReportStoreState, status: LoadingStatus) {
    state.status = status
  },
  [RESET_STATE](state: OptimizationReportStoreState) {
    state.report = JSON.parse(JSON.stringify(defaultReport))
    state.status = LoadingStatus.PENDING
  },
}

const actions = {
  fetch: ({ commit }: Context, assignmentId: string) => {
    commit(SET_LOADING_STATUS, LoadingStatus.LOADING)

    new AssignmentProxy()
      .getReportData(assignmentId)
      .then((response: AxiosItem<Report>) => {
        commit(FETCH_REPORT_DATA, response.data)

        commit(SET_LOADING_STATUS, LoadingStatus.SUCCESS)
      })
      .catch(() => {
        commit(SET_LOADING_STATUS, LoadingStatus.FAILURE)
      })
  },
  reset: ({ commit }: Context) => {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
