<template>
  <div>
    <div v-if="displayLoader">
      <div class="text-center">
        <div>
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <h2 class="pt-5">Loading assignment. It may take a few seconds.</h2>
      </div>
    </div>
    <NotFoundPage
      v-else-if="displayNotFound"
      title="Assignment not found"
      subtitle="The assignment you are looking for does not exit in our system. Please check if you followed correct link."
    >
      Or go to <router-link :to="{ name: assignmentsRoute }">Assignments List</router-link>
    </NotFoundPage>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { computed, type ComputedRef, onMounted } from 'vue'
import { none } from '@/services/Helpers'
import { useStore } from 'vuex'
import { LoadingStatus } from '@/const/LoadingStatus'
import type { Assignment, AssignmentWsEventData } from '@/types/Models/Assignment'
import NotFoundPage from '@/views/Components/NotFoundPage/NotFound.vue'
import { CUSTOMER_ASSIGNMENTS_LIST, STAFF_ASSIGNMENTS_LIST } from '@/types/router'

export default defineComponent({
  name: 'Assignment',
  components: { NotFoundPage },
  setup() {
    const store = useStore()
    const route = useRoute()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const isStaff = computed<boolean>(() => store.getters['account/isStaff'])

    const loadingStatus: ComputedRef<LoadingStatus> = computed(
      () => store.getters['assignment/assignmentSummary/loadingStatus'],
    )

    const displayLoader = computed(() => [LoadingStatus.PENDING, LoadingStatus.LOADING].includes(loadingStatus.value))
    const displayNotFound = computed(() => loadingStatus.value === LoadingStatus.FAILURE)

    const assignmentsRoute = computed(() => {
      return isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST
    })

    onMounted(() => {
      if (loadingStatus.value !== LoadingStatus.SUCCESS) {
        store.dispatch('assignment/assignmentSummary/fetch', route.params.id).catch(none)
      }
    })

    onBeforeRouteLeave((_to, _from, next) => {
      store.dispatch('assignment/assignmentSummary/reset')
      next()
    })

    onMounted(() => {
      /**
       * Use-cases
       *
       * - change 'validating' -> 'validation_complete'. For that we also need to update error counts, as action
       * buttons depend on whether assignment has errors or not
       */
      window.Echo.channel('App.Assignments').listen(
        '\\App\\Events\\Assignment\\AssignmentStatusChanged',
        (data: AssignmentWsEventData) => {
          if (data.assignment.id === assignment.value.id) {
            store.dispatch('assignment/assignmentSummary/setAssignmentStatus', data.assignment.status)
            store.dispatch('assignment/assignmentSummary/setErrorsCount', data.assignment)
          }
        },
      )
    })

    onBeforeUnmount(() => {
      window.Echo.leave('App.Assignments')
    })

    return {
      displayLoader,
      displayNotFound,
      loadingStatus,
      assignmentsRoute,
    }
  },
})
</script>
