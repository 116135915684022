export const PENDING = 'pending'
export const LOADING = 'loading'
export const SUCCESS = 'success'
export const FAILURE = 'failure'

export enum LoadingStatus {
  PENDING = 'pending',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILURE = 'failure',
}
