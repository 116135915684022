<template>
  <div>
    <SingleAssignmentDetails v-if="isSingle" />
    <BatchAssignmentDetails v-else />
  </div>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { SINGLE } from '@/const/AssignmentTypes'
import SingleAssignmentDetails from '@/views/Assignments/Details/Single.vue'
import BatchAssignmentDetails from '@/views/Assignments/Details/Batch.vue'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'AssignmentDetails',
  components: {
    BatchAssignmentDetails,
    SingleAssignmentDetails,
  },
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    const isSingle = computed(() => assignment.value?.type === SINGLE)

    return {
      assignment,
      isSingle,
    }
  },
})
</script>
