<template>
  <div class="mb-5">
    <label class="required fs-xl mt-0"> Sender Address </label>
    <CustomSelect
      :selected="senderAddressId"
      :options="senderAddresses"
      placeholder="Choose sender address"
      class="w-100"
      @selected="onSelect"
    >
      <template #custom_option="{ option }: { option: Address }">
        <div :class="'d-flex align-items-baseline item-' + option.id">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <div class="d-flex align-items-baseline">
              <span class="status" />
              <span class="name d-block mr-2">{{ option.company || option.fullName }} </span>
              <div>
                {{ option.countryCode }}, {{ option.city }},
                {{ option.addressLine }}
              </div>
              <div v-if="isStaff" class="d-flex ml-2">
                <b-badge v-if="option.customNumbers?.length" class="mt-0" variant="primary"> Customer Numbers</b-badge>
                <b-badge v-if="option.isDefault" class="ml-1 mt-0" variant="primary"> Default Address</b-badge>
                <b-badge v-if="option.hasCustomPrices" variant="success" class="ml-1"> Sender prices</b-badge>
                <b-badge v-if="option.hasCustomPriorities" variant="warning" class="ml-1"> Sender carriers</b-badge>
              </div>
            </div>
            <div>
              <b-button
                class="d-flex align-items-center justify-content-center action-btn"
                variant="outline-secondary"
                @click="openSenderAddressEditModal(option.id)"
              >
                <mdicon name="pencil-outline" class="pointer" size="18" />
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template #custom_list-footer>
        <div v-if="customerId" class="list-footer">
          <div
            class="create-client"
            @click="
              openModal(SENDER_ADDRESS_ACTION, {
                customerId: customerId,
                address: '',
                canHasCustomerNumbers: false,
              })
            "
          >
            Create New Sender Address
          </div>
        </div>
      </template>
    </CustomSelect>
    <div v-if="error" class="text-danger">
      {{ error }}
    </div>
    <SenderAddressActionModal :id="SENDER_ADDRESS_ACTION" :address-id="modalAddressId" @refresh="refreshAddresses" />
  </div>
</template>

<script lang="ts">
import type { Address } from '@/types/Models/Customer'
import { ref, computed, defineComponent, type ComputedRef, type PropType, watch, toRef } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { SENDER_ADDRESS_ACTION } from '@/const/ModalTypes'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'
import SenderAddressProxy from '@/proxies/Customer/SenderAddressProxy'
import type { AxiosCollection } from '@/types/Components/Response'
import SenderAddressTransformer from '@/transformers/SenderAddressTransformer'
import { none } from '@/services/Helpers'
import SenderAddressActionModal from '@/views/Modals/SenderAddress/SenderAddressActionModal.vue'

export default defineComponent({
  name: 'SenderAddress',
  components: {
    SenderAddressActionModal,
    CustomSelect,
  },
  props: {
    customerId: {
      type: String as PropType<string>,
      required: true,
    },
    senderAddressId: {
      type: String as PropType<string>,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['update:senderAddressId'],
  setup(props, { emit }) {
    const store = useStore()

    const customerIdRef = toRef(props, 'customerId')
    const senderAddresses = ref<Address[]>([])

    const modalAddressId = ref('')

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])

    const onSelect = (senderAddressId: string) => emit('update:senderAddressId', senderAddressId)

    const fetchSenderAddresses = (userId: string) => {
      new SenderAddressProxy(userId)
        .all()
        .then((response: AxiosCollection<Address>) => {
          senderAddresses.value = SenderAddressTransformer.fetchCollection(response.data)
        })
        .catch(none)
    }

    const refreshAddresses = () => fetchSenderAddresses(props.customerId)

    const openSenderAddressEditModal = (addressId: string) => {
      modalAddressId.value = addressId
      openModal(SENDER_ADDRESS_ACTION, {
        addressId,
        customerId: props.customerId,
        canHasCustomerNumbers: false,
      })
    }

    watch(customerIdRef, (value) => {
      if (value) {
        fetchSenderAddresses(value)
      }
    })

    watch(senderAddresses, (list: Address[]) => {
      const address = list.find((el) => el.isDefault) || list[0]
      if (address) {
        onSelect(address.id)
      }
    })

    return {
      isStaff,
      openModal,
      fetchSenderAddresses,
      openSenderAddressEditModal,
      senderAddresses,
      modalAddressId,
      onSelect,
      refreshAddresses,
      SENDER_ADDRESS_ACTION,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
