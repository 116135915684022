/* ============
 * Account Module
 * ============
 */

import optimizationReport from '@/store/modules/assignment/optimizationReport'
import assignmentSummary from '@/store/modules/assignment/assignmentSummary'
import type { RootStoreState, AssignmentStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'

export const RESET_LIST = 'RESET_LIST'
export const RESET_ITEMS_LIST = 'RESET_ITEMS_LIST'

export const defaultState: AssignmentStoreState = {
  version: '',
  itemsVersion: '',
}

type Context = ActionContext<AssignmentStoreState, RootStoreState>

const state: AssignmentStoreState = {
  ...defaultState,
}

const getters = {
  version: (state: AssignmentStoreState) => state.version,
  itemsVersion: (state: AssignmentStoreState) => state.itemsVersion,
}

const mutations = {
  [RESET_LIST](state: AssignmentStoreState) {
    state.version = new Date().toISOString()
  },
  [RESET_ITEMS_LIST](state: AssignmentStoreState) {
    state.itemsVersion = new Date().toISOString()
  },
}

const actions = {
  refresh: ({ commit }: Context) => {
    commit(RESET_LIST)
  },
  refreshItems: ({ commit }: Context) => {
    commit(RESET_ITEMS_LIST)
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    optimizationReport,
    assignmentSummary,
  },
}
