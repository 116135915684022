import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import {
  CANCELLED,
  COMPLETE,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPEN,
  OPTIMISING,
  PENDING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'
import type { AssignmentType, StatusType } from '@/types/Models/Assignment'

type StatusNameMap = {
  [key in AssignmentType]: {
    [key in StatusType]: string
  }
}
export const StatusName: StatusNameMap = {
  [SINGLE]: {
    [OPEN]: 'Buffering',
    [PENDING]: 'Pending',
    [COMPLETE]: 'Completed',
    [CANCELLED]: 'Cancelled',
    [VALIDATING]: 'Pending',
    [VALIDATION_COMPLETE]: 'Pending',
    [OPTIMISING]: 'Pending',
    [REPORT_RELEASED]: 'Price calculated',
    [REPORT_ACCEPTED]: 'Price accepted',
    [REPORT_DENIED]: 'Price rejected',
    [LABELS_CREATING]: 'Completed',
    [LABELS_RELEASED]: 'Label created',
  },
  [CONSOLIDATE]: {
    [OPEN]: 'Buffering',
    [PENDING]: 'Creating addresses',
    [COMPLETE]: 'Shipped',
    [CANCELLED]: 'Cancelled',
    [VALIDATING]: 'Creating addresses',
    [VALIDATION_COMPLETE]: 'Imported',
    [OPTIMISING]: 'Optimising',
    [REPORT_RELEASED]: 'Report created',
    [REPORT_ACCEPTED]: 'Report accepted',
    [REPORT_DENIED]: 'Report rejected',
    [LABELS_CREATING]: 'Labels creating',
    [LABELS_RELEASED]: 'Labels created',
  },
}
