import { type Ref, computed } from 'vue'
import { rulesFactory } from '@/composables/Pieces/Validation/rulesFactory'
import type { RulesBucket } from '@/composables/Pieces/Validation/Dimensions'

type Props = {
  distributionChannel: Ref<string>
  recipientCountry: Ref<string>
  isBusinessRecipient: Ref<boolean>
}

type Rules = {
  weight: RulesBucket
  height: RulesBucket
  width: RulesBucket
  length: RulesBucket
}
export const useValidationRules = ({ distributionChannel, recipientCountry, isBusinessRecipient }: Props) => {
  const dimensionsRules = computed(() => {
    return rulesFactory({
      distributionChannel: distributionChannel.value,
      recipientCountry: recipientCountry.value,
      isBusinessRecipient: isBusinessRecipient.value,
    })
  })

  const rules = computed(() => {
    return {
      weight: dimensionsRules.value.weight(),
      height: dimensionsRules.value.height(),
      width: dimensionsRules.value.width(),
      length: dimensionsRules.value.length(),
    }
  })

  const getFieldRules = (field: keyof Rules) => {
    return rules.value[field] || {}
  }

  return {
    getFieldRules,
  }
}
