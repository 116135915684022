/* ============
 * Assignment List Transformer
 * ============
 *
 * The transformer for the Assignment in the list.
 */

import ItemTransformer from '@/transformers/ItemTransformer'
import type { Assignment, AssignmentResponseData } from '@/types/Models/Assignment'
import SenderAddressTransformer from '@/transformers/SenderAddressTransformer'

export default class AssignmentsListTransformer {
  /**
   * Method used to transform a fetched Assignment.
   */
  static fetch(data: AssignmentResponseData): Assignment {
    return {
      ...data,
      itemsCount: data.domesticItemsCount + data.internationalItemsCount,
      item: data.item ? ItemTransformer.fetch(data.item) : null,
      senderAddress: data.senderAddress ? SenderAddressTransformer.fetch(data.senderAddress) : null,
    } as Assignment
  }

  /**
   * Method used to transform a fetched collection.
   */
  static fetchCollection(items: AssignmentResponseData[]) {
    return items.map((item) => this.fetch(item))
  }
}
