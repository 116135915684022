<template>
  <div>
    <div class="d-flex">
      <AssignmentType :assignment="assignment" />
      <AssignmentDistributionChannel :assignment="assignment" />
      <AssignmentServiceLevel :assignment="assignment" />
    </div>
    <div v-if="isStaff" class="d-flex pt-1">
      <HiddenPrices :label-id="`hidden-prices-${assignment.id}`" :hidden-prices="assignment.user.hidePrice" />
      <AssignmentCustomPrices :assignment="assignment" />
      <AssignmentCustomCarriers :assignment="assignment" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import { useStore } from 'vuex'

import AssignmentDistributionChannel from '@/views/Components/Labels/DistributionChannel.vue'
import AssignmentType from '@/views/Components/Labels/Type.vue'
import HiddenPrices from '@/views/Components/Labels/HiddenPrices.vue'
import AssignmentCustomPrices from '@/views/Components/Labels/CustomPrices.vue'
import AssignmentCustomCarriers from '@/views/Components/Labels/CustomCarriers.vue'
import AssignmentServiceLevel from '@/views/Components/Labels/ServiceLevel.vue'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'FeaturesCell',
  components: {
    AssignmentServiceLevel,
    AssignmentCustomCarriers,
    AssignmentCustomPrices,
    HiddenPrices,
    AssignmentType,
    AssignmentDistributionChannel,
  },
  props: {
    assignment: {
      type: Object as PropType<Assignment>,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const isStaff = computed(() => store.getters['account/isStaff'])

    return {
      isStaff,
    }
  },
})
</script>
