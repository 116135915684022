/* ============
 * Assignment Transformer
 * ============
 *
 * The transformer for the Assignment.
 */

import ItemTransformer from '@/transformers/ItemTransformer'
import type { AssignmentResponseData, AssignmentType, StatusType } from '@/types/Models/Assignment'
import type { Assignment } from '@/types/Models/Assignment'

export default class AssignmentTransformer {
  /**
   * Method used to transform a fetched Assignment.
   */
  static fetch(data: AssignmentResponseData): Assignment {
    const assignment: Assignment = {
      ...data,
      type: data.type as AssignmentType,
      status: data.status as StatusType,
      item: data.item ? ItemTransformer.fetch(data.item) : null,
      sourceFiles: data.sourceFiles || [],
      optimisationReportUrl: data.optimisationReportUrl || null,
      customerReference: data.customerReference || null,
      hasCustomPrices: data.hasCustomPrices || false,
      hasCustomPriorities: data.hasCustomPriorities || false,
      archived: data.archived || false,
      denyLabelsDownload: data.denyLabelsDownload || false,
    }

    return { ...data, ...assignment }
  }
}
