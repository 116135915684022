<template>
  <div class="row">
    <div class="col-12 col-md-9">
      <div class="form-group">
        <label class="required"> Select files </label>
        <div class="input-group">
          <div class="select-file">
            <input id="file" type="file" accept=".csv,.xlsx,.xls,.txt" @change="fileSelected($event)" />
            <div v-if="file">
              <span>{{ file.name }}</span>
            </div>
            <div v-else><span>Choose file</span> or drop file here</div>
          </div>
        </div>
        <div v-if="error" class="text-danger">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <label>File requirements</label>
      <div class="file-requirements">
        <div class="requirements-list">
          <div>Format: <strong>CSV, TXT, XLSX</strong></div>
          <div>Max size: <strong>50mb</strong></div>
        </div>
        <a
          v-b-tooltip="{
            title:
              'CSV files support most common delimiters are: a comma\n' +
              '<code>,</code>, semicolon <code>;</code> and tab <code>\\t</code>',
          }"
          href="https://cdn-pacsortonline.s3.eu-north-1.amazonaws.com/frontend/sample-spreadsheet.txt"
          class="download-sample"
          download="sample-spreadsheet.txt"
        >
          Download TXT example files
        </a>
        <a
          v-b-tooltip="{
            title:
              'TXT files support most common delimiters are: a comma\n' +
              '<code>,</code>, semicolon <code>;</code> and tab <code>\\t</code>',
          }"
          href="https://cdn-pacsortonline.s3.eu-north-1.amazonaws.com/frontend/sample-spreadsheet.csv"
          class="download-sample"
          download="sample-spreadsheet.csv"
        >
          Download CSV example files
        </a>
        <a
          href="https://cdn-pacsortonline.s3.eu-north-1.amazonaws.com/frontend/sample-spreadsheet.xlsx"
          class="download-sample"
          download="sample-spreadsheet.xlsx"
        >
          Download XLSX example file
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, type Ref } from 'vue'

export default defineComponent({
  name: 'BatchCreateForm',
  props: {
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['update:file'],
  setup(_, { emit }) {
    const file: Ref<File | null> = ref(null)

    const fileSelected = (event: Event) => {
      const input = event.target as HTMLInputElement
      file.value = input?.files?.[0] || null

      emit('update:file', file.value)
    }

    return {
      fileSelected,
      file,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
