<template>
  <div>
    <CreateEditForm />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'AssignmentCreate',
  components: {
    CreateEditForm: defineAsyncComponent(
      () => import('@/views/Components/Assignment/CreateEditForm/CreateEditForm.vue'),
    ),
  },
})
</script>
