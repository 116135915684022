<template>
  <div v-if="displayDownloads" class="assignment-action mr-4 d-flex">
    <b-button
      :disabled="!reportFile || isHidePrice"
      class="file-btn ml-0"
      :href="reportFile"
      target="_blank"
      :download="true"
    >
      <Tooltip :text="actionTooltip('report', !reportFile)">
        <mdicon name="file-pdf-box" />
      </Tooltip>
    </b-button>
    <b-button
      v-for="(sourceFile, key) in sourceFiles"
      :key="key"
      class="file-btn"
      :href="sourceFile.fileUrl"
      :download="true"
    >
      <Tooltip :text="actionTooltip('Source', true)">
        <mdicon name="Download" />
      </Tooltip>
    </b-button>
    <b-button class="file-btn ml-3" :href="exportLink" :download="true" target="_blank">
      <Tooltip text="Export assignment to CSV">
        <mdicon name="file-delimited-outline" />
      </Tooltip>
    </b-button>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent, computed, type ComputedRef } from 'vue'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import type { Assignment, SourceFile } from '@/types/Models/Assignment'
import { CONSOLIDATE } from '@/const/AssignmentTypes'

export default defineComponent({
  name: 'Downloads',
  components: {
    Tooltip,
  },
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    const isHidePrice: ComputedRef<boolean> = computed(() => store.getters['account/isHidePrice'])

    const sourceFiles: ComputedRef<SourceFile[]> = computed(() => assignment.value.sourceFiles || [])
    const reportFile: ComputedRef<string> = computed(() => assignment.value.optimisationReportUrl || '')
    const exportLink: ComputedRef<string> = computed(
      () => `${import.meta.env.VITE_APP_API_LOCATION}/assignments/${assignment.value.id}/export`,
    )

    const actionTooltip = (type = '', disabled = false) => (disabled ? '' : `Download ${type} file`)

    const displayDownloads: ComputedRef<boolean> = computed(() => assignment.value.type === CONSOLIDATE)

    return {
      isHidePrice,
      sourceFiles,
      reportFile,
      exportLink,
      actionTooltip,
      displayDownloads,
    }
  },
})
</script>

<style lang="scss" scoped>
.file-btn {
  background: transparent;
  padding: 0;
  border: 0;
  color: #000000;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    max-height: 24px;
  }

  &:first-child {
    margin-right: 18px;
  }

  &.disabled {
    opacity: 1;
    color: #c8c8c8;
  }
}
</style>
