<template>
  <div v-if="assignment.updatedAt" class="assignment-last-update d-flex align-items-center">
    <mdicon name="History" />
    Last edited {{ toTimeFromNow(assignment.updatedAt) }}
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent, computed, type ComputedRef } from 'vue'
import { toTimeFromNow } from '@/services/Helpers'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'LastEdited',
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    return {
      assignment,
      toTimeFromNow,
    }
  },
})
</script>
