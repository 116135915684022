<template>
  <div class="form-group">
    <div class="label required fs-xl">Assignment type</div>
    <div class="row">
      <div class="col-12 col-md-6">
        <input id="consolidated_type" v-model="type" name="assignment_type" type="radio" value="consolidate" />
        <label for="consolidated_type" class="radio-group">
          <span class="radio-title"> Batch shipment (Upload file) </span>
        </label>
      </div>
      <div class="col-12 col-md-6">
        <input id="single_type" v-model="type" name="assignment_type" type="radio" value="single" />
        <label for="single_type" class="radio-group">
          <span class="radio-title">Single package (Enter address)</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, watch } from 'vue'

export default defineComponent({
  name: 'AssignmentType',
  props: {
    assignmentType: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: ['update:assignmentType'],
  setup(props, { emit }) {
    const type = ref(props.assignmentType)

    watch(type, (value) => emit('update:assignmentType', value))

    watch(
      () => props.assignmentType,
      (value) => {
        type.value = value
      },
    )

    return {
      type,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
