import type { AssignmentCreationData } from '@/types/Models/Assignment'
import { onMounted, type Ref } from 'vue'
import { registerModals } from '@/services/Modal'
import { useRouter } from 'vue-router'
import {
  CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
  CREATE_NEW_CUSTOMER,
  REQUEST_ERROR,
  SENDER_ADDRESS_ACTION,
} from '@/const/ModalTypes'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'

type Props = {
  assignment: Ref<AssignmentCreationData>
}

export const useOnLifecycle = ({ assignment }: Props) => {
  const router = useRouter()

  onMounted(() => {
    registerModals(REQUEST_ERROR)
    registerModals(CREATE_NEW_CUSTOMER)
    registerModals(SENDER_ADDRESS_ACTION)
    registerModals(CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS)

    assignment.value.assignment_type = router.currentRoute.value.query?.assignmentType === SINGLE ? SINGLE : CONSOLIDATE
  })
}
