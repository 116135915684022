<template>
  <div>
    <div v-if="insufficientStatus">-</div>
    <div v-else>
      <div v-if="assignment.type === SINGLE">
        <CarrierRowWithIcon
          v-if="assignment.item?.carrierHandle"
          :carrier="assignment.item.carrierHandle"
          :only-icon="false"
        />
        <div v-if="assignment.item">
          <div>
            <small class="text-success">{{ assignment.item.shipmentNumber }}</small>
          </div>
          <small v-if="assignment.item.carrierHandle && assignment.item.announcementStatus">
            <AnnouncementStatus :carrier="assignment.item.carrierHandle" :status="assignment.item.announcementStatus" />
          </small>
        </div>
      </div>
      <div v-else class="d-flex">
        <div v-for="carrier of assignment.carriers" :key="carrier" class="mr-1">
          <CarrierRowWithIcon :carrier="carrier" :only-icon="assignment.carriers.length > 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import { defineComponent, computed } from 'vue'
import { SINGLE } from '@/const/AssignmentTypes'
import { OPEN, OPTIMISING, PENDING, VALIDATING, VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import AnnouncementStatus from '@/views/Components/Carrier/AnnouncementStatus.vue'

export default defineComponent({
  name: 'CarriersCell',
  components: { AnnouncementStatus, CarrierRowWithIcon },
  props: {
    assignment: {
      type: Object as () => Assignment,
      required: true,
    },
  },
  setup(props) {
    const statuses = [OPEN, PENDING, VALIDATING, VALIDATION_COMPLETE, OPTIMISING]

    const insufficientStatus = computed(() => statuses.includes(props.assignment.status))

    return {
      SINGLE,
      insufficientStatus,
    }
  },
})
</script>
