<template>
  <div v-if="serviceLevelIsSupported" class="form-group">
    <div class="label required fs-xl">Choose Service Level</div>
    <div class="row">
      <div class="col-12 col-md-6">
        <input id="service_level_priority" v-model="level" type="radio" value="priority" />
        <label for="service_level_priority" class="radio-group">
          <span class="radio-title">Priority</span>
        </label>
      </div>
      <div class="col-12 col-md-6">
        <input id="service_level_economy" v-model="level" type="radio" value="economy" />
        <label for="service_level_economy" class="radio-group">
          <span class="radio-title">Economy</span>
          <span v-if="showDeliveryTime" class="radio-description"> 1-4 days </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, type PropType, watch } from 'vue'
import { LETTER, PARCEL } from '@/const/DistributionChannels'

export default defineComponent({
  name: 'ServiceLevel',
  props: {
    distributionChannel: {
      type: String as PropType<string>,
      required: true,
    },
    serviceLevel: {
      type: String as PropType<string>,
      required: true,
    },
    isSwedenPicked: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: ['update:serviceLevel'],
  setup(props, { emit }) {
    const showDeliveryTime = computed(() => props.isSwedenPicked)

    const serviceLevelIsSupported = ![PARCEL, LETTER].includes(props.distributionChannel)

    const level = ref(props.serviceLevel)

    watch(level, (value) => emit('update:serviceLevel', value))

    watch(
      () => props.serviceLevel,
      (value) => {
        level.value = value
      },
    )

    return {
      showDeliveryTime,
      serviceLevelIsSupported,
      level,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
