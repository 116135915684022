<template>
  <BaseLabel
    v-if="customPriceType"
    :id="labelId"
    :short-text="shortText"
    :tooltip-text="tooltipText"
    :class-name="className"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import BaseLabel from '@/views/Components/Labels/BaseLabel.vue'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'AssignmentCustomPrices',
  components: { BaseLabel },
  props: {
    assignment: {
      type: Object as () => Assignment,
      required: true,
    },
  },
  setup(props) {
    const customPriceType = computed(() => {
      const { user, hasCustomPrices } = props.assignment
      if (hasCustomPrices) {
        return 'Assignment prices'
      }
      if (user && user.hasCustomPrices) {
        return 'Customer prices'
      }
      return null
    })

    const labelId = computed(() => `assignment-custom-prices-${props.assignment.id}`)
    const shortText = computed(() => 'CP')
    const tooltipText = computed(() => `Custom prices (${customPriceType.value}) are used`)
    const className = computed(() => 'custom-prices')

    return {
      customPriceType,
      labelId,
      shortText,
      tooltipText,
      className,
    }
  },
})
</script>
