import {
  CUSTOMER_ASSIGNMENTS_CREATE,
  CUSTOMER_ASSIGNMENTS_LIST,
  CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
  CUSTOMER_ASSIGNMENTS_SINGLE_EDIT,
  CUSTOMER_ASSIGNMENTS_DETAILS,
  CUSTOMER_PAGE,
  CUSTOMER_SHIPMENTS,
  CUSTOMER_STATISTICS,
} from '@/types/router'
import type { RouteRecordRaw } from 'vue-router'

const customer: Array<RouteRecordRaw> = [
  {
    path: '/customer',
    name: CUSTOMER_PAGE,
    component: () => import('@/views/Customer/Index.vue'),
    meta: {
      auth: true,
      resource: 'customer.area',
      action: 'manage',
      title: 'Customer',
    },
    children: [
      {
        path: 'assignments',
        component: () => import('@/views/Assignments/Index.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/Assignments/AssignmentsListPage.vue'),
            name: CUSTOMER_ASSIGNMENTS_LIST,
            meta: {
              title: 'Assignments',
              containerClass: 'container-large',
            },
          },
          {
            path: 'create',
            component: () => import('@/views/Assignments/Create.vue'),
            name: CUSTOMER_ASSIGNMENTS_CREATE,
            meta: {
              title: 'Create new assigment',
            },
          },
          {
            path: ':id',
            component: () => import('@/views/Assignments/Assignment.vue'),
            children: [
              {
                path: 'details',
                component: () => import('@/views/Assignments/Details/AssignmentDetails.vue'),
                name: CUSTOMER_ASSIGNMENTS_DETAILS,
                meta: {
                  title: 'Assigment',
                  containerClass: 'container-fluid',
                  hideFooter: true,
                },
              },
              {
                path: 'report',
                component: () => import('@/views/Assignments/Report/Index.vue'),
                name: CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
                meta: {
                  title: 'Report',
                  containerClass: 'container-fluid',
                  hideFooter: true,
                },
              },
              {
                path: 'edit',
                component: () => import('@/views/Assignments/Edit.vue'),
                name: CUSTOMER_ASSIGNMENTS_SINGLE_EDIT,
                meta: {
                  title: 'Edit Single Assignment',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'statistics',
        component: () => import('@/views/Statistics/Index.vue'),
        name: CUSTOMER_STATISTICS,
        meta: {
          title: 'Statistics',
          containerClass: 'container-large',
        },
      },
      {
        path: 'shipments',
        component: () => import('@/views/Shipments/Index.vue'),
        name: CUSTOMER_SHIPMENTS,
        meta: {
          title: 'Shipments feed',
          containerClass: 'container-large',
        },
      },
    ],
  },
]

export default customer
