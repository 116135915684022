<template>
  <div v-if="cost" class="d-flex align-items-center saved-cost">
    <span class="saved-cost__value"> {{ cost }} kr </span>
    <div v-if="percentSaved" ref="tooltipTrigger" class="saved-cost__percent">
      {{ formattedNumber(percentSaved) }}% Saved
    </div>
    <b-tooltip :target="tooltipTrigger" :noninteractive="true" title="In comparison to Postnord default pricing." />
  </div>
</template>

<script lang="ts">
import { formattedNumber } from '@/services/Helpers'
import { defineComponent, ref, computed, type PropType, type ComputedRef } from 'vue'
import type { Report } from '@/types/Models/Report'
import type { Assignment } from '@/types/Models/Assignment'
import { useStore } from 'vuex'

// todo - merge with src/views/Components/Assignment/Cells/Cost.vue

export default defineComponent({
  name: 'ShippingCostCell',
  setup() {
    const store = useStore()

    const tooltipTrigger = ref<HTMLElement | null>(null)
    const cost = computed(() => assignment.value.actualPrice)

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    const percentSaved = computed(() => {
      const { initialPrice, actualPrice } = assignment.value

      if (!initialPrice || !actualPrice) {
        return null
      }

      return Math.round(((initialPrice - actualPrice) / initialPrice) * 100)
    })

    return {
      cost,
      tooltipTrigger,
      formattedNumber,
      percentSaved,
    }
  },
})
</script>

<style lang="scss" scoped>
.saved-cost {
  display: flex;
  align-items: center;
  span {
    color: #08a762;
  }
  &__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background: rgba(8, 167, 98, 0.15);
    border-radius: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 144%;
    color: #08a762;
    padding: 0 5px;
    margin-left: 8px;
  }
}
</style>
