import { computed, type Ref } from 'vue'
import countriesEU from '@/assets/files/countriesEU.json'
import type { AssignmentCreationData } from '@/types/Models/Assignment'

type Props = {
  assignment: Ref<AssignmentCreationData>
}

export const useDerivedValues = ({ assignment }: Props) => {
  const outsideEu = computed(
    () => Boolean(assignment.value.item.country_code) && !countriesEU.includes(assignment.value.item.country_code),
  )
  const recipientCountry = computed(() => assignment.value.item.country_code)
  const isBusinessRecipient = computed(() => Boolean(assignment.value.item.company_name))
  const isSwedenPicked = computed(() => assignment.value.item.country_code === 'SE')

  return {
    outsideEu,
    recipientCountry,
    isBusinessRecipient,
    isSwedenPicked,
  }
}
