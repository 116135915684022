<template>
  <span v-if="text" :style="{ color }">
    {{ text }}
  </span>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { CITYMAIL, ASENDIA } from '@/const/Carriers'
import type { KeyAccessor } from '@/types/Helpers/Arrays'

const texts: KeyAccessor<KeyAccessor<string>> = {
  [CITYMAIL]: {
    pending: 'EDI-file pending',
    processing: 'EDI-file uploading',
    success: 'EDI-file uploaded',
    failure: 'EDI-file upload failed',
  },
  [ASENDIA]: {
    pending: 'Manifest pending',
    processing: 'Manifest sending',
    success: 'Manifest sent',
    failure: 'Manifest sending failed',
  },
}

const colors: KeyAccessor<string> = {
  pending: '#999999',
  processing: '#999999',
  success: 'rgb(25, 135, 84)',
  failure: '#ff0000',
}

export default defineComponent({
  name: 'AnnouncementStatus',
  props: {
    carrier: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const text = computed(() => texts[props.carrier]?.[props.status] || null)
    const color = computed(() => colors[props.status])

    return {
      text,
      color,
    }
  },
})
</script>

<style lang="scss"></style>
