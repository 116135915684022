<template>
  <li>
    <span class="label">{{ name }}:</span>
    <span v-if="$slots.default"><slot></slot></span>
    <span v-else>{{ value }}</span>
  </li>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  name: 'SummaryLine',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number] as PropType<string | number>,
      required: false,
      default: null,
    },
  },
})
</script>
