<template>
  <b-button size="md" variant="link" :style="{ backgroundColor: '#FFCA10', color: '#151515' }" class="ml-3" :to="to">
    <i class="fa fa-list" />
    View assignment
  </b-button>
</template>

<script lang="ts">
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { CUSTOMER_ASSIGNMENTS_DETAILS, STAFF_ASSIGNMENTS_DETAILS } from '@/types/router'

export default defineComponent({
  name: 'ViewAssignment',
  setup() {
    const store = useStore()

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const assignmentId: ComputedRef<string> = computed(() => store.getters['assignment/assignmentSummary/getId'])
    const routeName: ComputedRef<string> = computed(() =>
      isStaff.value ? STAFF_ASSIGNMENTS_DETAILS : CUSTOMER_ASSIGNMENTS_DETAILS,
    )
    const to = computed(() => ({
      name: routeName.value,
      params: { id: assignmentId.value },
    }))

    return {
      to,
    }
  },
})
</script>
