<template>
  <div class="easy-data-table-footer">
    <div class="d-flex align-items-center">
      <div><b>Rows per page:</b> {{ rowsPerPageActiveOption }}</div>
      <div class="ml-2 pad">
        <b-dropdown no-caret lazy class="pagination-block">
          <template #button-content>
            <div class="pagination-arrow" />
          </template>
          <template v-for="item in rowsPerPageOptions" :key="`pagination_key_${item}`">
            <b-dropdown-item @click="updateRowsPerPageActiveOption(item)">
              {{ item }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
      <div class="ml-2">{{ currentPageFirstIndex }} - {{ currentPageLastIndex }} of {{ clientItemsLength }} items</div>
      <div class="ml-2 right">
        <div class="wrap-page-btn">
          <span><b>Page:</b> {{ currentPaginationNumber }} of {{ maxPaginationNumber }}</span>
          <span class="btn-table-action" @click="prevPage">
            <b-icon
              icon="caret-left-fill"
              scale="1"
              shift-h="-25"
              :class="{ pointer: !isFirstPage }"
              :variant="isFirstPage ? 'secondary' : 'dark'"
            />
          </span>
          <span class="btn-table-action" @click="nextPage">
            <b-icon
              icon="caret-right-fill"
              scale="1"
              shift-h="25"
              :class="{ pointer: !isLastPage }"
              :variant="isLastPage ? 'secondary' : 'dark'"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from 'vue'
import { usePagination, useRowsPerPage } from 'use-vue3-easy-data-table'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'

export default defineComponent({
  name: 'TablePagination',
  components: { BIcon },
  props: {
    serverItemsLength: {
      type: Number,
      required: false,
      default: 0,
    },
    serverOptionsRef: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}),
    },
    dataTableRef: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const dataTable = computed(() => props.dataTableRef)
    return {
      ...usePagination(dataTable),
      ...useRowsPerPage(dataTable),
    }
  },
})
</script>
