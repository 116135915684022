<template>
  <div class="container">
    <h1 class="mb-5">Creating addresses...</h1>
    <div>
      Our system is creating the addresses for the assignment, it may take up to a minute. This page will be
      automatically refreshed once the addresses are ready.
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BatchCreatingItems',
  components: {},
})
</script>
