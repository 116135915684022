<template>
  <modal-wrap
    ref="downloadWarningModal"
    :name="modalName"
    :header-class="'service-level-header'"
    :body-class="'service-level-modal'"
    :hide-footer="false"
    size="lg"
  >
    <template #modal-title> The labels may be outdated or already used</template>
    <template #default>
      <div v-if="assignmentsWithDeny.length > 0" class="pt-4">
        <OneAssignment v-if="assignmentsWithDeny.length === 1" :assignment="assignmentsWithDeny[0]" />
        <MultipleAssignments v-else :assignments="assignmentsWithDeny" />
      </div>
      <div class="pt-2">
        If you really need to download these labels you can proceed anyway, but you might consider creating new labels.
      </div>
    </template>
    <template #modal-footer>
      <b-button size="md" class="btn-grey mr-3" @click="closeModal"> Cancel</b-button>
      <b-button variant="success" size="md" class="btn-green" @click="downloadLabels"> Download anyway</b-button>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent } from 'vue'
import { DOWNLOAD_LABELS, DOWNLOAD_LABELS_WARNING } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import OneAssignment from '@/views/Modals/Assignment/DownloadLabelsWarning/OneAssignment.vue'
import MultipleAssignments from '@/views/Modals/Assignment/DownloadLabelsWarning/MultipleAssignments.vue'
import type { Assignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'DownloadLabelsWarning',
  components: { MultipleAssignments, OneAssignment, ModalWrap },
  setup() {
    const store = useStore()

    const modalName = DOWNLOAD_LABELS_WARNING

    const assignments: ComputedRef<Assignment[]> = computed(
      () => store.getters['vueNextModal/modalProps'](modalName)?.assignments || [],
    )

    const assignmentsWithDeny: ComputedRef<Assignment[]> = computed(() =>
      assignments.value.filter((a) => a.denyLabelsDownload),
    )

    const closeModal = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const downloadLabels = () => {
      if (!assignments.value) {
        return
      }

      const ids = [assignments.value.map((a) => a.id)]

      closeModal()
      openModal(DOWNLOAD_LABELS, { ids })
    }

    return {
      modalName,
      assignmentsWithDeny,
      closeModal,
      downloadLabels,
    }
  },
})
</script>

<style lang="scss" scoped></style>
