<template>
  <div class="optimization-top-list">
    <div class="row m-auto w-100">
      <div class="col-4 p-0">
        <ul class="mb-0 first-list">
          <SummaryLine name="Customer" :value="senderName" />
          <SummaryLine name="Reference" :value="assignment.reference" />
          <SummaryLine name="Date" :value="formattedDate(assignment.createdAt, 'YYYY-MM-DD')" />
          <SummaryLine name="Distribution channel" :value="getDistributionChannelTxt(assignment.distributionChannel)" />
        </ul>
      </div>
      <div class="col-4 p-0">
        <ul class="mb-0">
          <SummaryLine name="Service level" :value="ucFirst(assignment.serviceLevel)" />
          <SummaryLine name="Distribution start" :value="formattedDate(assignment.createdAt, 'YYYY-MM-DD')" />
          <SummaryLine name="Total quantity" :value="itemsCount" />
          <SummaryLine v-if="fileName" name="File name" :value="fileNameTrim(fileName)" />
        </ul>
      </div>
      <div v-if="isStaff" class="col-4 p-0">
        <ul class="mb-0">
          <SummaryLine name="Customer number" :value="ucFirst(assignment.user?.clientNumber || '-')" />
          <SummaryLine name="Invoice reference" :value="customerReference || '-'" />
          <SummaryLine name="Short name" :value="senderShortName || '-'" />
          <SummaryLine name="Total weight (kg)" :value="totalWeight" />
        </ul>
      </div>
    </div>

    <div class="optimization-top-logo ml-3">
      <Logo />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { fileNameTrim, formattedDate, ucFirst } from '@/services/Helpers'
import { getDistributionChannelTxt } from '@/services/Utils'
import Logo from '@/views/Components/Elements/Icons/Logo.vue'
import type { Assignment } from '@/types/Models/Assignment'
import type { Report } from '@/types/Models/Report'
import SummaryLine from '@/views/Assignments/Report/Summary/Line.vue'

export default defineComponent({
  name: 'ReportSummary',
  components: { SummaryLine, Logo },
  setup() {
    const store = useStore()

    const reportData: ComputedRef<Report> = computed(() => store.getters['assignment/optimizationReport/all'])
    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const senderName: ComputedRef<string> = computed(() => store.getters['assignment/assignmentSummary/senderName'])
    const senderShortName: ComputedRef<string | null> = computed(
      () => store.getters['assignment/assignmentSummary/senderShortName'],
    )
    const isStaff = computed(() => store.getters['account/isStaff'])
    const itemsCount = computed(() => assignment.value.itemsCount)
    const fileName: ComputedRef<string | null> = computed(() => assignment.value.sourceFiles[0]?.name || null)
    const customerReference = computed(() => assignment.value.customerReference)

    const totalWeight = computed(() => reportData.value.info.totalWeight)

    return {
      assignment,
      isStaff,
      senderName,
      senderShortName,
      itemsCount,
      fileName,
      customerReference,
      ucFirst,
      fileNameTrim,
      formattedDate,
      getDistributionChannelTxt,
      totalWeight,
    }
  },
})
</script>

<style lang="scss">
.optimization-top-logo {
  z-index: 0;
  width: 115px;
  height: 115px;
}
.optimization-top-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid #ccc;
}

.optimization-top-list li {
  line-height: 21px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #757575;
}

.optimization-top-list span.label {
  color: #151515;
  min-width: 115px;
  display: inline-block;
  margin-right: 5px;
}
.first-list {
  span.label {
    min-width: 80px;
  }
}
</style>
