import { BOX, LETTER, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import { ADDRESS_DELIVERY, SERVICE_POINT_DELIVERY } from '@/const/AssignmentDeliveryTypes'
import { GIFT, MIXED, OTHER, RETURN, DOCUMENTS, SALES_OF_GOODS, COMMERCIAL_SAMPLE } from '@/const/ProformaInvoiceTypes'
import { ucFirst } from '@/services/Helpers'
import type { SelectOptions } from '@/types/Components/SelectOptions'
import { ECONOMY, PRIORITY } from '@/const/AssignmentServiceLevels'

export const orderByTimeDesc = (a: any, b: any) => (a.eventTime > b.eventTime ? -1 : 1)

export const getDistributionChannelTxt = (type = '') => {
  switch (type) {
    case PACKAGE:
      return 'Paket'
    case PARCEL:
      return 'Parcel'
    case LETTER:
      return 'Letter'
    case BOX:
      return 'Locker'
    default:
      return ''
  }
}

export const getServiceLevelTxt = (input: string | null) => {
  switch (input) {
    case ECONOMY:
      return 'Economy'
    case PRIORITY:
      return 'Priority'
    default:
      return ''
  }
}

export const getAssignmentTypeTxt = (type = '') => {
  switch (type) {
    case SINGLE:
      return 'Single-item assignment'
    case CONSOLIDATE:
      return 'Batch assignment'
    default:
      return ''
  }
}

export const getDeliveryTypeLabel = (deliveryType: string) => {
  switch (deliveryType) {
    case SERVICE_POINT_DELIVERY:
      return 'Service point delivery'
    case ADDRESS_DELIVERY:
      return 'Address delivery'
    default:
      return ''
  }
}

export const getProformaInvoiceTypes = (): SelectOptions[] => {
  const types = [SALES_OF_GOODS, GIFT, DOCUMENTS, COMMERCIAL_SAMPLE, RETURN, MIXED, OTHER]

  return types.reduce<SelectOptions[]>(
    (prev, val) => ({
      ...prev,
      [val]: { text: ucFirst(val.replace(/_/g, ' ')), value: val },
    }),
    [],
  )
}

export const isEqual = (value: any, other: any): boolean => {
  if (value === other) return true

  if (value == null || typeof value !== 'object' || other == null || typeof other !== 'object') {
    return false
  }

  const keysValue = Object.keys(value)
  const keysOther = Object.keys(other)

  if (keysValue.length !== keysOther.length) {
    return false
  }

  for (const key of keysValue) {
    if (!keysOther.includes(key) || !isEqual(value[key], other[key])) {
      return false
    }
  }

  return true
}

export const unionBy = <T>(array: T[], otherArray: T[], iteratee: keyof T): T[] => {
  const result = [...array]

  const getValue = (item: T) => item[iteratee]

  for (const item of otherArray) {
    const computedValue = getValue(item)
    if (!result.some((existingItem) => isEqual(getValue(existingItem), computedValue))) {
      result.push(item)
    }
  }

  return result
}
