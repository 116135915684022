<template>
  <div class="assignment-action d-flex mr-4 user-info">
    <div class="d-flex flex-column mr-3">
      <div v-if="user.shortName" class="color-grey">Short name</div>
      <div class="color-black">
        {{ user.shortName }}
      </div>
    </div>
    <div v-if="user.clientNumber" class="d-flex flex-column mr-3">
      <div class="color-grey">Client №</div>
      <div class="color-black">
        {{ senderAddress?.clientNumber || user.clientNumber }}
      </div>
    </div>
    <div v-if="user.company" class="d-flex flex-column">
      <div class="color-grey">Company</div>
      <div class="color-black">
        {{ senderAddress?.company || user.company }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent, computed, type ComputedRef, type PropType } from 'vue'
import { pluralize, toTimeFromNow } from '@/services/Helpers'
import { CUSTOMER_ASSIGNMENTS_LIST, STAFF_ASSIGNMENT_PRICES, STAFF_ASSIGNMENTS_LIST } from '@/types/router'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import AssignmentStatus from '@/views/Components/PageParts/AssignmentStatus.vue'
import type { Assignment, SourceFile } from '@/types/Models/Assignment'
import type { RouterLink } from '@/types/Components/Router'
import type { PureAbility } from '@casl/ability'
import type { User } from '@/types/Models/User'
import type { Address } from '@/types/Models/Customer'
import { CONSOLIDATE } from '@/const/AssignmentTypes'
import Reference from '@/views/Components/Assignment/Stripe/Reference.vue'

export default defineComponent({
  name: 'UserInfo',
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const user: ComputedRef<User> = computed(() => assignment.value.user)
    const senderAddress: ComputedRef<Address | null | undefined> = computed(() => assignment.value.senderAddress)

    return {
      user,
      senderAddress,
    }
  },
})
</script>
