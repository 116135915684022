<template>
  <div class="col-12">
    <div v-if="!packingSlip.economyPreview && !packingSlip.priorityPreview" class="text-center p-5">
      No Preview Available
    </div>
    <div class="d-flex pt-3">
      <div
        v-if="packingSlip.economyPreview"
        :class="packingSlip.economyPreview ? (packingSlip.priorityPreview ? 'col-6' : 'col-12') : ''"
      >
        <div class="text-center pb-3">Economy</div>
        <div>
          <img
            v-show="economyLoaded"
            :src="`${packingSlip.economyPreview}?v=${uniqueString}`"
            class="col-12"
            alt=""
            @load="economyLoaded = true"
          />
          <div v-if="!economyLoaded" class="col-12 text-center">
            <b-spinner class="mt-5" variant="success" small />
          </div>
        </div>
      </div>
      <div
        v-if="packingSlip.priorityPreview"
        :class="packingSlip.priorityPreview ? (packingSlip.economyPreview ? 'col-6' : 'col-12') : ''"
      >
        <div class="text-center pb-3">Priority</div>
        <div>
          <img
            v-show="priorityLoaded"
            :src="`${packingSlip.priorityPreview}?v=${uniqueString}`"
            class="col-12"
            alt=""
            @load="priorityLoaded = true"
          />
          <div v-if="!priorityLoaded" class="col-12 text-center">
            <b-spinner class="mt-5" variant="success" small />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex pt-5 pr-4 pl-4 mr-0 justify-content-between">
      <b-button class="file-btn" variant="secondary" @click="editPackingSlip()"> Edit </b-button>
      <b-button
        class="file-btn"
        variant="success"
        :disabled="!packingSlip.downloadUrl"
        @click="downloadFile(packingSlip.downloadUrl)"
      >
        <b-icon icon="download" class="mr-2" variant="white" small />
        Download
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { downloadFile } from '@/services/FileActions'
import { PACKING_SLIP_EDIT } from '@/const/ModalTypes'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'

export default defineComponent({
  name: 'PackingSlipPreview',
  components: { BIcon },
  setup() {
    const store = useStore()
    const packingSlip = computed(() => store.getters['packingSlip/getPackingSlip'])
    const isCustomer = computed(() => store.getters['account/isCustomer'])
    const economyLoaded = ref(false)
    const priorityLoaded = ref(false)
    const uniqueString = ref(Math.random().toString(36).substring(7))

    const editPackingSlip = () => {
      openModal(PACKING_SLIP_EDIT, { id: packingSlip.value.id })
    }

    return {
      packingSlip,
      isCustomer,
      economyLoaded,
      priorityLoaded,
      uniqueString,
      editPackingSlip,
      downloadFile,
    }
  },
})
</script>
