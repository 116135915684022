<template>
  <div v-if="!short">
    <div class="d-flex">
      <b-icon icon="circle-fill" scale="1.3" :style="circleStyle" />
      <div class="pl-2 ptop">
        {{ statusName }}
      </div>
    </div>
  </div>
  <div v-else>
    <BaseLabel :id="labelId" :short-text="statusShortName" :tooltip-text="statusName" :color="labelColor" />
  </div>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import { defineComponent, computed, type PropType } from 'vue'
import { useAssignmentStatus } from '@/composables/Assignment/useAssignmentStatus'
import BaseLabel from '@/views/Components/Labels/BaseLabel.vue'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'

export default defineComponent({
  name: 'AssignmentStatus',
  components: { BIcon, BaseLabel },
  props: {
    assignment: {
      type: Object as PropType<Assignment>,
      required: true,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const assignment = computed(() => props.assignment)

    const { statusName, statusShortName, circleStyle } = useAssignmentStatus({ assignment })

    const labelId = `assignment-type-${assignment.value.id}`
    const labelColor = circleStyle.value.color

    return {
      statusName,
      statusShortName,
      circleStyle,
      labelId,
      labelColor,
    }
  },
})
</script>
