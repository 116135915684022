import ItemsProxy from '@/proxies/ItemsProxy'
import ItemProductProxy from '@/proxies/ItemProductProxy'
import ItemAddressesProxy from '@/proxies/ItemAddressesProxy'
import { notify } from '@kyvg/vue3-notification'
import { none } from '@/services/Helpers'
import type { ItemsStoreState, RootStoreState } from '@/types/Store'
import type { ActionContext } from 'vuex'

export const RESET_CHECKED = 'RESET_CHECKED'
export const SET_CHECKED = 'SET_CHECKED'
export const SET_ERRORS_ONLY = 'SET_ERRORS_ONLY'
export const SET_LABELS_ERRORS_ONLY = 'SET_LABELS_ERRORS_ONLY'
export const SET_FATAL_ERROR_COUNT = 'SET_FATAL_ERROR_COUNT'

const state: ItemsStoreState = {
  checkedItems: [],
  errorsOnly: false,
  labelsErrorsOnly: false,
  fatalErrorsCount: 0,
}

type Context = ActionContext<ItemsStoreState, RootStoreState>

const getters = {
  checkedItems: (state: ItemsStoreState) => state.checkedItems,
  errorsOnly: (state: ItemsStoreState) => state.errorsOnly,
  labelsErrorsOnly: (state: ItemsStoreState) => state.labelsErrorsOnly,
  fatalErrorsCount: (state: ItemsStoreState) => state.fatalErrorsCount,
}

const mutations = {
  [RESET_CHECKED](state: ItemsStoreState) {
    state.checkedItems = []
  },
  [SET_CHECKED](state: ItemsStoreState, checked: string[]) {
    state.checkedItems = checked
  },
  [SET_ERRORS_ONLY](state: ItemsStoreState, flag: boolean) {
    state.errorsOnly = flag
  },
  [SET_LABELS_ERRORS_ONLY](state: ItemsStoreState, flag: boolean) {
    state.labelsErrorsOnly = flag
  },
  [SET_FATAL_ERROR_COUNT](state: ItemsStoreState, count: number) {
    state.fatalErrorsCount = count
  },
}

const actions = {
  setFatalErrorCount: ({ commit }: Context, count: number) => {
    commit(SET_FATAL_ERROR_COUNT, count)
  },
  setErrorsOnly: ({ commit }: Context, flag: boolean) => {
    commit(SET_ERRORS_ONLY, flag)
  },
  setLabelsErrorsOnly: ({ commit }: Context, flag: boolean) => {
    commit(SET_LABELS_ERRORS_ONLY, flag)
  },
  updateItem: (_: any, { item }: any) => new ItemsProxy().update(item.id, item),
  updateItemAddress: (_: any, { id, itemAddressField }: any) => new ItemAddressesProxy().update(id, itemAddressField),
  updateItemProduct: (_: any, { id, itemProductField }: any) => new ItemProductProxy().update(id, itemProductField),
  validateItem: ({ dispatch }: Context, itemId: string) =>
    new ItemsProxy()
      .validate(itemId)
      .then((request) => {
        dispatch(`assignment/assignmentSummary/refresh`, null, { root: true }).then(none)
        return request.data
      })
      .catch((e) => {
        notify({ group: 'error', text: e.message || 'Server error. Please try again later.' })
      }),
  // items list checked items
  updateCheckedItems: ({ commit }: Context, checked: string[]) => {
    commit(SET_CHECKED, checked)
  },
  resetCheckedItems: ({ commit }: Context) => {
    commit(RESET_CHECKED)
  },
  deleteCheckedItems: (_: any, { items }: { items: string[] }) => {
    const promisesArray: Promise<any>[] = []
    items.forEach((id: string) => {
      promisesArray.push(new ItemsProxy().destroy(id))
    })
    return Promise.all(promisesArray)
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
