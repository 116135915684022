<template>
  <div v-if="displayPricingLink" class="assignment-function d-flex">
    <router-link
      :to="dynamicPricesLink"
      :disabled="!isItemsEditable"
      :class="'btn ml-3 btn-height-md btn-outline-secondary no-wrap' + (isItemsEditable ? '' : ' disabled')"
    >
      Dynamic Pricing
    </router-link>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent, computed, type ComputedRef } from 'vue'
import { STAFF_ASSIGNMENT_PRICES } from '@/types/router'
import type { Assignment } from '@/types/Models/Assignment'
import type { PureAbility } from '@casl/ability'
import { CONSOLIDATE } from '@/const/AssignmentTypes'
import { PENDING, VALIDATING, VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'

export default defineComponent({
  name: 'PricingLink',
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const ability: ComputedRef<PureAbility> = computed(() => store.getters['ability'])

    const isItemsEditable: ComputedRef<boolean> = computed(
      () => store.getters['assignment/assignmentSummary/isItemsEditable'],
    )

    const dynamicPricesLink = computed(() => ({
      name: STAFF_ASSIGNMENT_PRICES,
      params: { id: assignment.value.id },
    }))

    const displayPricingLink = computed(
      () =>
        assignment.value.type === CONSOLIDATE &&
        [PENDING, VALIDATING, VALIDATION_COMPLETE].includes(assignment.value.status) &&
        ability.value.can('manage', 'prices'),
    )

    return {
      isItemsEditable,
      dynamicPricesLink,
      displayPricingLink,
    }
  },
})
</script>

<style lang="scss">
.border-r {
  border-right: 1px solid #cccccc;
}
.border-l {
  border-left: 1px solid #cccccc;
}
.file-btn {
  background: transparent;
  padding: 0;
  border: 0;
  color: #000000;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    max-height: 24px;
  }

  &:first-child {
    margin-right: 18px;
  }

  &.disabled {
    opacity: 1;
    color: #c8c8c8;
  }
}
.btn-height-md {
  height: 40px !important;
  line-height: 20px !important;
}
.user-info {
  .color-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #757575;
  }
  .color-black {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #151515;
  }
}

.border-l-none {
  border-left: none;
}

.assignment-function {
  .btn {
    width: auto;
    max-height: 40px;
    min-height: auto;
    padding: 9px 24px;
    font-size: 14px;
    color: #757575;
    border-color: #cccccc;
  }

  .btn:hover {
    color: #fff;
  }
}

.user-info {
  .color-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #757575;
  }
  .color-black {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #151515;
  }
}
</style>
