import { LETTER, PARCEL } from '@/const/DistributionChannels'
import { LetterDimensions } from '@/composables/Pieces/Validation/LetterDimensions'
import { PackageDimensions } from '@/composables/Pieces/Validation/PackageDimensions'
import { ParcelBusinessDimensions } from '@/composables/Pieces/Validation/ParcelBusinessDimensions'
import { ParcelConsumerDimensions } from '@/composables/Pieces/Validation/ParcelConsumerDimensions'
import type { DimensionsRules } from '@/composables/Pieces/Validation/Dimensions'

type Options = {
  distributionChannel: string
  recipientCountry: string
  isBusinessRecipient: boolean
}

export const rulesFactory = (options: Options): DimensionsRules => {
  switch (true) {
    case options.distributionChannel === LETTER:
      return new LetterDimensions()
    case options.distributionChannel === PARCEL:
      return options.isBusinessRecipient ? new ParcelBusinessDimensions() : new ParcelConsumerDimensions()
    default:
      return new PackageDimensions(options.recipientCountry)
  }
}
