<template>
  <div>
    <div>
      {{ toAddressString(shipment.recipient) }}
    </div>

    <div>
      <small class="text-muted">
        {{ addressOwner(shipment.recipient) }}
      </small>
    </div>

    <div v-if="notifications.length" class="text-danger pt-2">
      <div v-for="(notification, index) in notifications" :key="index">
        <i class="fa fa-exclamation-triangle fa-fw" />
        {{ notification }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import { adjustedServicePointProblemDescription } from '@/const/Texts'
import type { AdjustedShipment, Shipment } from '@/types/Models/Shipment'
import { useAddressTransformers } from '@/composables/Address/useAddressTransformers'

export default defineComponent({
  name: 'RecipientCell',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const hasServicePoints: ComputedRef<boolean> = computed(() =>
      props.shipment.servicePoints ? props.shipment.servicePoints.length > 0 : false,
    )
    const orderedPieces: ComputedRef<AdjustedShipment[]> = computed(() =>
      (props.shipment.shipments ? [...props.shipment.shipments] : []).sort(
        (a: AdjustedShipment, b: AdjustedShipment) => b.runningNumber - a.runningNumber,
      ),
    )
    const secondaryShipment: ComputedRef<AdjustedShipment | null> = computed(() => orderedPieces.value[1] || null)
    const hasAdjustedData: ComputedRef<boolean> = computed(() => Boolean(secondaryShipment.value))
    const hasAdjustedServicePointProblem: ComputedRef<boolean> = computed(
      () => hasAdjustedData.value && !hasServicePoints.value,
    )

    const notifications: ComputedRef<string[]> = computed(() => {
      const buffer: string[] = []

      if (props.shipment.hasTrackingError && props.shipment.lastTrackingEventDescription) {
        buffer.push(props.shipment.lastTrackingEventDescription)
      }

      if (hasAdjustedServicePointProblem.value) {
        buffer.push(adjustedServicePointProblemDescription)
      }

      return buffer
    })

    const { toAddressString, addressOwner } = useAddressTransformers()

    return {
      notifications,
      toAddressString,
      addressOwner,
    }
  },
})
</script>
