<template>
  <div>
    <b-badge v-if="insufficientStatus" variant="warning"> Pending</b-badge>
    <div v-else-if="!hidePrices.value || isStaff.value" class="d-flex align-items-center saved-cost">
      <span class="saved-cost__value"> {{ assignment.actualPrice }} kr </span>
      <span v-if="percentSaved" class="saved-cost__percent"> {{ percentSaved }}% </span>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { OPEN, OPTIMISING, PENDING, VALIDATING, VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'

export default defineComponent({
  name: 'CostCell',
  props: {
    assignment: {
      type: Object as () => Assignment,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const hidePrices = computed(() => store.getters['account/isHidePrice'])

    const statuses = [OPEN, PENDING, VALIDATING, VALIDATION_COMPLETE, OPTIMISING]

    const insufficientStatus = computed(() => statuses.includes(props.assignment.status))

    const percentSaved = computed(() => {
      const { initialPrice, actualPrice } = props.assignment

      if (!initialPrice || !actualPrice) {
        return null
      }

      return Math.round(((initialPrice - actualPrice) / initialPrice) * 100)
    })

    return {
      insufficientStatus,
      isStaff,
      hidePrices,
      percentSaved,
    }
  },
})
</script>

<style lang="scss" scoped>
.saved-cost {
  display: flex;
  align-items: center;

  span {
    color: #08a762;
  }

  .saved-cost__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background: rgba(8, 167, 98, 0.15);
    border-radius: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 144%;
    color: #08a762;
    padding: 0 5px;
    margin-left: 8px;
  }
}
</style>
