//
import type { AssignmentResponse } from '@/types/Models/Assignment'
import type { AssignmentCreationData, ErrorsList } from '@/types/Models/Assignment'
import { type Reactive, type Ref, toRaw } from 'vue'
import { buildFormData } from '@/services/Form'
import { openModal } from '@/services/Modal'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { none } from '@/services/Helpers'
import { SINGLE } from '@/const/AssignmentTypes'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import { PARCEL } from '@/const/DistributionChannels'
import { ADDRESS_DELIVERY, SERVICE_POINT_DELIVERY } from '@/const/AssignmentDeliveryTypes'
import { CUSTOMER_ASSIGNMENTS_DETAILS, STAFF_ASSIGNMENTS_DETAILS } from '@/types/router'
import { CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS, REQUEST_ERROR } from '@/const/ModalTypes'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import ItemTransformer from '@/transformers/ItemTransformer'

type Props = {
  assignment: Ref<AssignmentCreationData>
  isStaff: Ref<boolean>
  errorsList: Reactive<ErrorsList>
}

export const useOnSubmit = ({ assignment, isStaff, errorsList }: Props) => {
  const store = useStore()
  const router = useRouter()

  const proxy = new AssignmentProxy()

  return () => {
    let promise = null
    if (assignment.value.assignment_type !== SINGLE) {
      errorsList.customer_id = isStaff.value && assignment.value.customer_id === '' ? 'Select client, please.' : ''
    }

    if (errorsList.customer_id === '') {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER }).then(none)
      errorsList.file = ''
      const data: any = { ...toRaw(assignment.value) }
      if (!data?.sender_address_id) {
        delete data.sender_address_id
      }
      if (!isStaff.value) {
        delete data.customer_id
      }

      // transform item
      data.item = ItemTransformer.create(data.item)

      if (assignment.value.assignment_type === SINGLE) {
        delete data.file
        if (assignment.value.distribution_channel === PARCEL && data.item) {
          // move this hack to backend
          data.item.delivery_type = data.item.company_name ? ADDRESS_DELIVERY : SERVICE_POINT_DELIVERY
        }

        data.optimize = true
        data.create_report = false
        data.create_labels = false

        promise = proxy.create(data)
      } else {
        delete data.item
        const formData = new FormData()
        buildFormData(formData, data)

        if (!data.file) {
          formData.delete('file')
        }

        promise = proxy.create(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      }

      promise
        .then((raw: AssignmentResponse) => {
          store.dispatch('assignment/assignmentSummary/setAssignment', raw).then(none)
          router
            .push({
              name: isStaff.value ? STAFF_ASSIGNMENTS_DETAILS : CUSTOMER_ASSIGNMENTS_DETAILS,
              params: { id: raw.data.id },
            })
            .then(none)
        })
        .catch((e: any) => {
          const fileErrors = e?.errors?.file || []
          if (fileErrors.length > 0) {
            openModal(CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS, { fileErrors })
          } else if (e?.errors) {
            openModal(REQUEST_ERROR, { errors: e.errors })
          }
        })
        .finally(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
        })
    }
  }
}
