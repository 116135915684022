<template>
  <modal-wrap
    :id="modalName"
    :name="modalName"
    :header-class="'border-0 service-level-header'"
    :body-class="'service-level-modal assignment-form'"
    :hide-footer="false"
    size="md"
  >
    <template #modal-title>
      <h4 class="mb-0">Select file</h4>
    </template>
    <template #default>
      <div class="col-12">
        <div class="form-group">
          <div class="input-group">
            <div class="select-file">
              <input id="file" type="file" accept=".csv,.xlsx,.xls,.txt" @change="fileSelected($event)" />
              <div v-if="file && file.name">
                <span>{{ file.name }}</span>
              </div>
              <div v-else><span>Choose file</span> or drop file here</div>
            </div>
          </div>
          <div v-if="errors.length" class="text-danger">
            <b>Error: </b>
            <span v-for="(error, key) in errors" :key="key">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="form-check">
        <input
          id="replace"
          v-model="replace"
          :value="true"
          type="checkbox"
          class="form-check-input"
          name="sendNotifications"
        />
        <label class="form-check-label" for="replace"> Replace existing file and all items with new file </label>
      </div>
    </template>
    <template #modal-footer>
      <b-button size="md" class="btn-grey mr-3" @click="uploadFile">Upload file</b-button>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, type Ref } from 'vue'
import { registerModals } from '@/services/Modal'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { ATTACH_SOURCE_FILE_MODAL } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import type { UploadFile } from '@/types/Components/UploadFile'

export default defineComponent({
  name: 'AttachSourceFileModal',
  components: { ModalWrap },
  setup() {
    const store = useStore()

    const modalName = ATTACH_SOURCE_FILE_MODAL
    const errors: Ref<string[]> = ref([])
    const replace = ref(false)
    const file: Ref<UploadFile> = ref({ name: '', file: null })

    const assignmentId = computed(() => store.getters['assignment/assignmentSummary/getId'])
    const proxy = new AssignmentProxy()

    const uploadFile = () => {
      const formData = new FormData()

      if (file.value.file) {
        formData.append('file', file.value.file)
      }

      formData.append('replace', replace.value ? '1' : '0')

      errors.value = []
      proxy
        .attachSourceFile(assignmentId.value, formData)
        .then(() => {
          store.dispatch('vueNextModal/closeModal', modalName)
          notify({
            type: 'success',
            text: 'File uploaded successfully',
          })
        })
        .catch((error) => {
          Object.keys(error.errors).forEach((key) => {
            errors.value.push(error.errors[key].join(', '))
          })
        })
    }

    const fileSelected = (event: any) => {
      const selectedFile = event.target.files[0]
      file.value = { name: selectedFile.name, file: selectedFile }
    }

    onMounted(() => {
      registerModals(modalName)
    })

    return {
      file,
      errors,
      modalName,
      assignmentId,
      fileSelected,
      uploadFile,
      replace,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
