import { computed, type Ref } from 'vue'
import { SINGLE } from '@/const/AssignmentTypes'
import type { AssignmentCreationData } from '@/types/Models/Assignment'

type Props = {
  assignment: Ref<AssignmentCreationData>
  isSwedenPicked: Ref<boolean>
}

export const useValidationRules = ({ assignment, isSwedenPicked }: Props) => {
  return computed(() => ({
    deliveryType: {
      required: true,
    },
    email: {
      email: true,
      required: true,
    },
    lettersField: {
      alpha_spaces: true,
      required: true,
      min: 3,
    },
    description: {
      required: assignment.value.item.country_code !== 'SE' && assignment.value.assignment_type === SINGLE,
      min: 3,
      max: 30,
    },
    reference: {
      required: false,
      min: 3,
      max: 34,
    },
    message: {
      required: false,
      min: 3,
    },
    customer_id: {
      required: true,
      min: 3,
    },
    file: {
      required: true,
      min: 1,
    },
    emailRule: {
      emailPhoneRequired:
        (isSwedenPicked.value && assignment.value.item.phone_number.length === 0) || !isSwedenPicked.value,
      email: true,
    },
    phoneRule: {
      simplePhone: assignment.value.item.country_code,
      emailPhoneRequired: (isSwedenPicked.value && assignment.value.item.email.length === 0) || !isSwedenPicked.value,
    },
  }))
}
