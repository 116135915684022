<template>
  <div class="assignment-footer d-flex justify-content-between align-items-center">
    <div class="footer-status">
      <ItemsErrors v-if="displayItemsErrors" />
      <LabelsCreatedErrors v-if="labelsNotCreatedCount > 0" />
    </div>

    <div class="footer-btn ml-auto d-flex align-items-center">
      <div v-if="assignment.pickupDate" class="pickup-date">
        <span class="pickup-date-title">Pickup date</span>
        <span class="pickup-date-value d-block">
          {{ formattedDate(assignment.pickupDate, 'MMMM DD') }}
        </span>
      </div>

      <AssignmentActions origin="assignment" />
    </div>
  </div>
</template>

<script lang="ts">
import type { Assignment } from '@/types/Models/Assignment'
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { formattedDate } from '@/services/Helpers'
import ItemsErrors from '@/views/Components/PageParts/ItemsErrors.vue'
import LabelsCreatedErrors from '@/views/Components/Labels/LabelsCreatedErrors.vue'
import AssignmentActions from '@/views/Components/Assignment/Actions/AssignmentActions.vue'

export default defineComponent({
  name: 'AssignmentFooter',
  components: {
    AssignmentActions,
    ItemsErrors,
    LabelsCreatedErrors,
  },
  props: {
    destinationType: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const labelsNotCreatedCount = computed(() => store.getters['assignment/assignmentSummary/getLabelsNotCreatedCount'])
    const displayItemsErrors = computed(() =>
      props.destinationType === 'domestic'
        ? assignment.value && assignment.value.domesticErrorsCount > 0
        : assignment.value && assignment.value.internationalErrorsCount > 0,
    )

    return {
      assignment,
      displayItemsErrors,
      labelsNotCreatedCount,
      formattedDate,
    }
  },
})
</script>

<style scoped>
.pickup-date-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}
.pickup-date-value {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: #08a762;
}
</style>
