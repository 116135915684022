<template>
  <div>
    <b-dropdown-item v-if="actionRoute" :to="actionRoute">
      <i v-if="action.icon" :class="['fa', action.icon]" />
      {{ action.name }}
    </b-dropdown-item>
    <b-dropdown-item v-else-if="actionLink" :href="actionLink">
      <i v-if="action.icon" :class="['fa', action.icon]" />
      {{ action.name }}
    </b-dropdown-item>
    <b-dropdown-item v-else @click="actionFn">
      <i v-if="action.icon" :class="['fa', action.icon]" />
      {{ action.name }}
    </b-dropdown-item>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { useActionHandlers } from '@/services/AssignmentsListActions'
import type { Assignment } from '@/types/Models/Assignment'
import type { Action } from '@/types/Components/Actions'

export default defineComponent({
  name: 'DropdownItem',
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
    assignment: {
      type: Object as PropType<Assignment>,
      required: true,
    },
  },
  setup(props) {
    return {
      ...useActionHandlers(props.assignment, props.action),
    }
  },
})
</script>
