<template>
  <div v-b-tooltip="{ title: tooltipText }" :class="['label-wrap', className]" :style="{ background: color }">
    <span>{{ shortText }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseLabel',
  props: {
    id: {
      type: String,
      required: true,
    },
    shortText: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
})
</script>
